import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
} from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) => ({
  popup: {
    background: '#F7EEDD', 
    fontFamily: 'Lato, sans-serif', 
    fontSize: '16px', 
    padding: theme.spacing(1), 
    border: "solid 2px #000",
    borderRadius: "5px",
    boxShadow: "6px 6px 0px 0px rgba(0,0,0,0.09)",
    transition: ".3s",
    "&:hover": {
      transition: ".3s",
    },
    "&.left": {
      textAlign: "center",
    },
    // Estilos específicos para cada tipo de alerta
    "&.info": {
      background: "#e7f3fe", 
    },
    "&.alert": {
      background: "#fff3cd", 
    },
    "&.error": {
      background: "#fde2e2", 
    },
  },
  title: {
    fontFamily: 'Georgia, serif', 
    fontSize: '20px', 
  },
  contentText: {
    fontFamily: 'Verdana, sans-serif', 
    fontSize: '14px', 
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  actions: {
    justifyContent: 'space-between', 
    padding: theme.spacing(2),
    fontSize: '14px' 
  },
}));


interface AlertDialogProps {
  isOpen: boolean;
  title: string;
  content: string;
  onAgree: () => void;
  onDisagree: () => void;
  type: "default" | "info" | "alert" | "error";
}


export default function AlertDialog({
  isOpen,
  title,
  content,
  onAgree,
  onDisagree,
  type,
}: AlertDialogProps) {

  const classes = useStyles();


  return (
    <Dialog
      open={isOpen}
      onClose={onDisagree}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: `${classes.popup} ${type}` }}
    >
      <DialogTitle className={classes.title} id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText} id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={onDisagree} color="secondary">
          Não
        </Button>
        <Button onClick={onAgree} color="primary" autoFocus>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
}
