import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  ReactNode,
} from "react";
import { useNavigate } from "react-router-dom";
import useSignatureDetectionStore, { Region } from './components/SignatureDetection/store';


interface AuthContextType {
  isLoggedIn: boolean;
  login: () => void;
  logout: () => void;
  loadUserSignatures: () => Promise<void>;
}


const AuthContext = createContext<AuthContextType>({
  isLoggedIn: false,
  login: () => { },
  logout: () => { },
  loadUserSignatures: async () => { },
});


const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const user = useSignatureDetectionStore((state) => state.user);
  const API_URL = process.env.REACT_APP_API_URL || "https://vereditoink.soilore.com/api/";
  const navigate = useNavigate();


  const getJwtToken = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("jwt_token="))
      ?.split("=")[1];
    return cookieValue;
  };


  const handleGoogleCallback = useCallback(
    async (code: string) => {
      if (!code) {
        console.error("AuthProvider - handleGoogleCallback - Código de autorização não encontrado.");
        return;
      }
      try {
        console.log("AuthProvider - handleGoogleCallback - Enviando requisição para o backend..."); // Log antes da requisição
        const response = await fetch(`${API_URL}auth/google/callback`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ code }),
        });
  
        if (!response.ok) {
          // Verifica se houve erro de autenticação
          if (response.status === 401) {
            console.error("Usuário não encontrado no banco de dados.");
            alert("Ops, algo deu errado com o login. Tente novamente ou entre em contato conosco.")
            return;
          }
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        if (data.jwt_token) {
          document.cookie = `jwt_token=${data.jwt_token}; path=/; secure; samesite=strict`;
        }
        setIsLoggedIn(true);
        navigate("/Verify");
      } catch (error) {
        console.error("Erro durante o callback do Google:", error);
        console.error("AuthProvider - handleGoogleCallback - Erro durante o callback do Google:", error);
      }
    },
    [API_URL, navigate],
  );

  // checkLoginStatus memorizada com useCallback
  const checkLoginStatus = useCallback(async () => {
    try {
      // 1. Verifica se há um código na URL
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      if (code) {
        // Se houver um código, processa o callback do Google
        await handleGoogleCallback(code);
      }
      const response = await fetch(`${API_URL}auth/user`, {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setIsLoggedIn(data.authenticated);
      } else {
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.error("Erro ao verificar o status de login:", error);
      setIsLoggedIn(false);
    }
  }, [API_URL, handleGoogleCallback]);


  const login = async () => {
    try {
      const response = await fetch(`${API_URL}auth/google/login/`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      window.location.href = data.authorization_url;
    } catch (error) {
      console.error("Erro durante o login:", error);
      console.error("AuthProvider - login - Erro durante o login:", error);
    }
  };


  const logout = async () => {
    try {
      // 1. Faz a requisição de logout para o backend
      await fetch(`${API_URL}auth/logout`, {
        method: "POST",
      });

      // 2. Limpa o LocalStorage
      localStorage.removeItem('signatureDetectionState');

      // 3. Define o estado de login como false
      setIsLoggedIn(false);

      // 4. Redireciona para a página inicial APÓS o logout
      navigate("/");
    } catch (error) {
      console.error("Erro durante o logout:", error);
      // Lidar com o erro de logout, se necessário
    }
  };


  const fetchUserData = useCallback(async () => {
    try {
      const jwt_token = getJwtToken();
      const response = await fetch(`${API_URL}data/user`, {
        headers: {
          Authorization: `Bearer ${jwt_token}`,
        },
      });
      if (response.ok) {
        const userData = await response.json();
        useSignatureDetectionStore.setState({ user: userData });
      } else {
        console.error('Erro ao buscar dados do usuário:', response.status);
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
    }
  }, [API_URL]);


  const loadUserSignatures = useCallback(async () => {
    if (user) {
      try {
        const response = await fetch(`${API_URL}data/load-signatures/${user.user_id}`);
        if (response.ok) {
          const data = await response.json();
          const newRegions: Region[] = data.images.map(
            (img: { id: number; box: [number, number, number, number]; name: string; data: string }) => ({
              id: img.id,
              box: img.box,
              regionName: img.name,
              image_url: img.data,
              currentZone: 'noSigned',
            }),
          );
          useSignatureDetectionStore.setState({ loadedSignatures: newRegions });
          // Mescla as regiões carregadas com as existentes, 
          // priorizando as existentes para evitar perder edições do usuário
          useSignatureDetectionStore.setState((prevState) => ({
            detectionResult: {
              image_size: [0, 0],
              regions: [
                ...prevState.detectionResult?.regions || [], // Mantém as regiões existentes
                ...newRegions.filter(
                  (newRegion) => !prevState.detectionResult?.regions.some((existingRegion) => existingRegion.id === newRegion.id),
                ), // Adiciona apenas as novas regiões que não existem
              ],
              image_url: '',
            },
          }));
        } else {
          console.error('Erro ao carregar as assinaturas do usuário:', response.status);
        }
      } catch (error) {
        console.error('Erro ao carregar as assinaturas do usuário:', error);
      }
    }
  }, [user, API_URL]);


  useEffect(() => {
    checkLoginStatus();
    fetchUserData();
  }, [checkLoginStatus, fetchUserData, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      loadUserSignatures();
    }
  }, [isLoggedIn, loadUserSignatures]);


  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, loadUserSignatures }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;