
import VerifiedIcon from '@mui/icons-material/Verified';
import { createGlobalStyle } from "styled-components";
import React, { useState, useEffect } from "react";
import useSignatureDetectionStore from "./store";
import { useCredits } from './CreditsContext';
import { useAuth } from '../../AuthProvider';
import Footer from "./Footer";
import InfoDialog from "../InfoDialog";


const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #FAF6F1;
  }
`;


const Dashboard = function () {
	const [openMenu, setOpenMenu] = React.useState(true);
	const [openMenu1, setOpenMenu1] = React.useState(false);
	const [openMenu2, setOpenMenu2] = React.useState(false);
	const [openMenu3, setOpenMenu3] = React.useState(false);
	const [openMenu4, setOpenMenu4] = React.useState(false);

	const handleBtnClick = () => {
		setOpenMenu(!openMenu);
		setOpenMenu1(false);
		setOpenMenu2(false);
		setOpenMenu4(false);
		document.getElementById("balance").classList.remove("active");
		document.getElementById("ranking").classList.remove("active");

		document.getElementById("awards").classList.remove("active");
	};
	const handleBtnClick1 = () => {
		setOpenMenu1(!openMenu1);
		setOpenMenu2(false);
		setOpenMenu(false);
		setOpenMenu4(false);
		document.getElementById("balance").classList.remove("active");
		document.getElementById("ranking").classList.remove("active");

		document.getElementById("awards").classList.remove("active");
	};
	const handleBtnClick2 = () => {
		setOpenMenu2(!openMenu2);
		setOpenMenu(false);
		setOpenMenu1(false);
		setOpenMenu4(false);
		document.getElementById("balance").classList.remove("active");
		document.getElementById("ranking").classList.remove("active");

		document.getElementById("awards").classList.remove("active");
	};

	const handleBtnClick4 = () => {
		setOpenMenu4(!openMenu4);
		setOpenMenu(false);
		setOpenMenu1(false);
		setOpenMenu2(false);
		document.getElementById("balance").classList.remove("active");
		document.getElementById("ranking").classList.remove("active");

		document.getElementById("awards").classList.remove("active");
	};

	const [errorMessage, setErrorMessage] = useState("");
	const [messageType, setMessageType] = useState("error");
	const { credits, handleCheckout } = useCredits();
	const { isLoggedIn, login, logout } = useAuth();

	const user = useSignatureDetectionStore((state) => state.user);
	const loadedSignatures = useSignatureDetectionStore((state) => state.loadedSignatures);

	const [openAlert, setOpenAlert] = useState(false);
	const [alertData, setAlertData] = useState(null);

	const handleOpenAlert = (data) => {
		setAlertData(data);
		setOpenAlert(true);
	};

	const handleCloseAlert = () => {
		setOpenAlert(false);
		setAlertData(null);
	};

	const handleCheckoutClick = (pack) => {
		if (isLoggedIn) {
			handleCheckout(pack);
		} else {
			handleOpenAlert({
				title: "Autenticação Necessária!",
				content: "Você precisa estar logado para comprar créditos.",
				type: "info"
			});
		}
	};

	useEffect(() => {
	}, [user, credits]);


	return (
		<div>
			<GlobalStyles />
			<>
				<section
					className="container no-bottom"
					style={{ marginTop: "6rem", paddingTop: "1rem" }}>
					<div className="row">
						<div className="spacer-double"></div>
						<div className="col-md-12">
							<div className="d_profile de-flex">
								<div className="de-flex-col">
									<div className="profile_avatar">
										<img
											src="./img/wallet.png"
											alt=""
										/>
										<i className="icon-box style-3"><VerifiedIcon fontSize="medium" sx={{ color: "#000" }} /></i>
										<div className="profile_name">
											{isLoggedIn && user ? (
												<h4>
													<span>
														<h2 style={{ marginBottom: "-0.1rem" }}>𖡀 {user.name}</h2>
													</span>
													<span className="profile_username">{user.email}</span>
													<span
														id="wallet"
														className="profile_wallet">
													</span>
													<button
														id="btn_copy"
														title="Copy Text"
														onClick={logout}>
														Sair
													</button>
												</h4>
											) : (
												<div style={{ marginRight: "1rem", fontSize: "18px" }}>
													Clique em 'Entrar' para exibir sua conta.
												</div>
											)}
										</div>
									</div>
								</div>

								<div className="profile_follow de-flex">
									<div className="de-flex-col">
										<div className="profile_name">
											{isLoggedIn && user ? (
												<div className="profile_username" style={{ marginRight: "18.0rem" }}>
													<h4>Saldo Total</h4>
													<p className="user_balance">
														<span className="user_balance">
															<h4 style={{ color: "#00999b" }}>{credits}</h4>
														</span>
														<span className="user_balance">
															<h5>Créditos</h5>
														</span>
													</p>
													<p className="user_balance">
														<span className="user_balance">
															<h3 style={{ color: "#FF7F50" }}>{user.queries}</h3>
														</span>
														<span className="user_balance">
															<h6>Consultas Hoje</h6>
														</span>
													</p>

												</div>

											) : (
												<div style={{ marginRight: "1rem", fontSize: "18px" }}>
													Clique em 'Entrar' para exibir sua conta.
												</div>
											)}
										</div>
									</div>
									<div className="profile_follow de-flex">
										<div className="de-flex-col">
											{isLoggedIn && user ? (
												<></>
											) : (
												<button
													className="btn-main"
													onClick={login}
													
													theme="light">
													Entrar
												</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="container">
					<div className="row">
						<div className="col-md-8">
							{openMenu && (
								<ul className="activity-list">
									<li className="act_withdraw">
										<img
											className="lazy"
											src="./img/withdraw.png"
											alt=""
										/>
										<div className="act_list_text">
											<h4>Carregar Saldo</h4>
											<div
												className="act_list_date"
												style={{ marginTop: "1rem", marginBottom: "0rem" }}>
												<button
													className="btn-main btn-main-small"
													onClick={() => handleCheckoutClick("Start")}
													onSuccess={(result) => {
														setErrorMessage("Success!");
														setMessageType("success");
													}}
													onError={(error) => {
														setErrorMessage(error.reason);
														setMessageType("error");
													}}
													theme="dark">
													Pack 15 Créditos - R$ 49,00
												</button>
											</div>
											<div
												className="act_list_date"
												style={{ marginTop: "1rem", marginBottom: "0rem" }}>
												<button
													className="btn-main btn-main-small"
													onClick={() => handleCheckoutClick("Pro")}
													onSuccess={(result) => {
														setErrorMessage("Success!");
														setMessageType("success");
													}}
													onError={(error) => {
														setErrorMessage(error.reason);
														setMessageType("error");
													}}
													theme="dark">
													Pack 40 Créditos - R$ 89,00
												</button>
											</div>
											<div
												className="act_list_date"
												style={{ marginTop: "1rem", marginBottom: "0rem" }}>
												<button
													className="btn-main btn-main-small"
													onClick={() => handleCheckoutClick("Ultimate")}
													onSuccess={(result) => {
														setErrorMessage("Success!");
														setMessageType("success");
													}}
													onError={(error) => {
														setErrorMessage(error.reason);
														setMessageType("error");
													}}
													theme="dark">
													Pack 100 Créditos - R$ 189,00
												</button>
												<div className="spacer-half"></div>
											</div>
										</div>
									</li>

									<li className="act_balance">
										<img
											className="lazy"
											src="./img/balance.png"
											alt=""
										/>
										<div className="act_list_text">
											<h4>Minha Conta</h4>
											{isLoggedIn && user ? (
												<>
													<div className="act_list_date">➟ 1 ➟ Consulta Grátis / Dia </div>
													<div className="">⎯⎯⎯</div>
													<span className="act_list_date">➟ {credits} ➟ Créditos Disponíveis</span>
													<div className="">⎯⎯⎯⎯</div>
													<span className="act_list_date">
														➟ ${user.amount_paid},00 BRL ➟ Última Recarga
													</span>
													<div className="">⎯⎯⎯⎯⎯⎯</div>
												</>
											) : (
												<div >
													Clique em 'Entrar' para exibir sua conta.
												</div>
											)}
										</div>
										<div className="spacer-half"></div>
									</li>


									<li className="act_ranking">
										<img
											className="lazy"
											src="./img/awards.png"
											alt=""
										/>
										<div className="act_list_text">
											<h4>Minhas Assinaturas</h4>
											<div className="spacer-half"></div>
											{isLoggedIn && user ? (
												<>
													{loadedSignatures.length > 0 ? (
														<>
															Salvas <span className="color">| Para salvar, excluir ou baixar acesse a página 'Verificar'. </span>
															<div className="spacer-half"></div>
															<div className="signature-list">
																{loadedSignatures.map((signature) => (
																	<div className="signature-item" key={signature.id}>
																		<p>{signature.regionName || 'Assinatura'}</p>
																		{signature.image_url && (
																			<img src={signature.image_url} alt={signature.regionName} />
																		)}
																	</div>
																))}
															</div>
														</>
													) : (
														<p>Você ainda não salvou nenhuma assinatura.</p>
													)}
												</>
											) : (
												<p>Faça login para visualizar suas assinaturas salvas.</p>
											)}
										</div>
									</li>
								</ul>
							)}

							{openMenu1 && (
								<ul className="activity-list">
									<li className="act_balance">
										<img
											className="lazy"
											src="./img/balance.png"
											alt=""
										/>
										<div className="act_list_text">
											<h4>Minha Conta</h4>
											{isLoggedIn && user ? (
												<>
													<span className="act_list_date">➟ {credits} ➟ Créditos Disponíveis</span>
													<div className="">⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯</div>
													<span className="act_list_date">
														➟ ${user.amount_paid},00 BRL ➟ Última Recarga
													</span>
													<div className="">⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯</div>
													<div className="spacer-half"></div>
													<div className="">
														<h5>Comprovantes</h5>
														<span className="color">Histórico de Pagamentos</span>
														<div
															className="act_list_date"
															style={{ marginTop: "1rem", marginBottom: "0rem" }}>
															<button
																className="btn-main btn-main-small"
																onClick={() => window.open("https://billing.stripe.com/p/login/9AQ9C3eO4gX38Jq5kk", "_blank")}
																theme="dark">
																Acessar
															</button>
															<span
																className="color"
																style={{ marginLeft: "1rem" }}></span>
														</div>
													</div>
												</>
											) : (
												<div >
													Clique em 'Entrar' para exibir sua conta.
												</div>
											)}
										</div>
										<div className="spacer-half"></div>
									</li>
								</ul>
							)}

							{openMenu2 && (
								<ul className="activity-list">
									<li className="act_ranking">
										<img
											className="lazy"
											src="./img/awards.png"
											alt=""
										/>
										<div className="act_list_text">
											<h4>Minhas Assinaturas</h4>
											<div className="spacer-half"></div>
											{isLoggedIn && user ? (
												<>
													{loadedSignatures.length > 0 ? (
														<>
															Salvas <span className="color">| Para salvar, excluir ou baixar acesse a página 'Verificar'. </span>
															<div className="spacer-half"></div>
															<div className="signature-list">
																{loadedSignatures.map((signature) => (
																	<div className="signature-item" key={signature.id}>
																		<p>{signature.regionName || 'Assinatura'}</p>
																		{signature.image_url && (
																			<img src={signature.image_url} alt={signature.regionName} />
																		)}
																	</div>
																))}
															</div>
														</>
													) : (
														<p>Você ainda não salvou nenhuma assinatura.</p>
													)}
												</>
											) : (
												<p>Faça login para visualizar suas assinaturas salvas.</p>
											)}
										</div>
										<div className="spacer-half"></div>
									</li>
								</ul>
							)}

							{openMenu4 && (
								<ul className="activity-list">
									<li className="act_awards">
										<img
											className="lazy"
											src="./img/withdraw.png"
											alt=""
										/>
										<div className="act_list_text">
											<h4>Pack 15</h4>
											R$ 49,00 <span className="color">| 15 Créditos | Expira em 12 meses |</span>
											<div
												className="act_list_date"
												style={{ marginTop: "1rem", marginBottom: "0rem" }}>
												<button
													className="btn-main btn-main-small"
													onClick={() => handleCheckoutClick("Start")}
													onSuccess={(result) => {
														setErrorMessage("Success!");
														setMessageType("success");
													}}
													onError={(error) => {
														setErrorMessage(error.reason);
														setMessageType("error");
													}}
													theme="dark">
													Pack 15
												</button>
												<span
													className="color"
													style={{ marginLeft: "1rem" }}></span>
											</div>
										</div>
									</li>

									<li className="act_awards">
										<img
											className="lazy"
											src="./img/withdraw.png"
											alt=""
										/>
										<div className="act_list_text">
											<h4>Pack 40</h4>
											R$ 89,00 <span className="color">| 40 Créditos | Expira em 24 meses |</span>
											<div
												className="act_list_date"
												style={{ marginTop: "1rem", marginBottom: "0rem" }}>
												<button
													className="btn-main btn-main-small"
													onClick={() => handleCheckoutClick("Pro")}
													onSuccess={(result) => {
														setErrorMessage("Success!");
														setMessageType("success");
													}}
													onError={(error) => {
														setErrorMessage(error.reason);
														setMessageType("error");
													}}
													theme="dark">
													Pack 40
												</button>
												<span
													className="color"
													style={{ marginLeft: "1rem" }}></span>
											</div>
										</div>
									</li>

									<li className="act_awards">
										<img
											className="lazy"
											src="./img/withdraw.png"
											alt=""
										/>
										<div className="act_list_text">
											<h4>Pack 100</h4>
											R$ 189,00 <span className="color">| 100 Créditos | Expira em 30 meses |</span>
											<div
												className="act_list_date"
												style={{ marginTop: "1rem", marginBottom: "0rem" }}>
												<button
													className="btn-main btn-main-small"
													onClick={() => handleCheckoutClick("Ultimate")}
													onSuccess={(result) => {
														setErrorMessage("Success!");
														setMessageType("success");
													}}
													onError={(error) => {
														setErrorMessage(error.reason);
														setMessageType("error");
													}}
													theme="dark">
													Pack 100
												</button>
												<span
													className="color"
													style={{ marginLeft: "1rem" }}>

												</span>
											</div>
										</div>
										<div className="spacer-half"></div>
									</li>
								</ul>
							)}
						</div>

						<div className="col-md-4" style={{ marginTop: "-2.5rem" }}>
							<span className="filter__l">Filtro
							</span>
							<span
								className="filter__r"
								onClick={handleBtnClick}>
								Limpar
							</span>
							<div className="spacer-half"></div>
							<div className="clearfix"></div>
							<ul className="activity-filter">
								<li
									id="awards"
									className="filter_by_awards"
									onClick={handleBtnClick4}>
									<i className="icon_gift_alt"></i>Créditos
								</li>
								<li
									id="balance"
									className="filter_by_balance"
									onClick={handleBtnClick1}>
									<i className=""></i>Minha Conta
								</li>
								<li
									id="ranking"
									className="filter_by_ranking"
									onClick={handleBtnClick2}>
									<i className="icon_pushpin_alt"></i>Assinaturas
								</li>
							</ul>
						</div>
						<InfoDialog
							data-testid="info-dialog"
							isOpen={openAlert}
							title={alertData?.title || ""}
							content={alertData?.content || ""}
							type={alertData?.type || "info"}
							onCancel={handleCloseAlert}
						/>
					</div>
				</section>
			</>
			<Footer />
		</div>
	);
};
export default Dashboard;
