import React from "react";
import { Routes, Route } from "react-router-dom";
import SignatureLoader from "./components/SignatureDetection/SignatureLoader";
import Dashboard from "./components/SignatureDetection/Dashboard";
import Home from "./components/SignatureDetection/Home";


const AppRoutes: React.FC = () => {
  return (
      <Routes>
        <Route path="/" element={<Home />} /> 
        <Route path="/Verify" element={<SignatureLoader />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        {/* Defina as rotas para cada componente de página */}
        {/* ... outras rotas */}
      </Routes>
  );
};

export default AppRoutes;