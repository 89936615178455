
import { createGlobalStyle } from "styled-components";
import { Link } from 'react-router-dom';
import { useAuth } from '../../AuthProvider';


const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #FAF6F1;
    border-bottom: solid 0px #ccc !important;   
  }
`;


const Benefit = function () {
	const { isLoggedIn, login } = useAuth();

	return (
		<div>
			<GlobalStyles />
			<section
				className="container"
				style={{ marginTop: "1rem", paddingTop: "1rem" }}>
				<div className="row">
					<div className="col-md-6 pl-md-5 text-center">
						<div
							className="box-url"
							style={{
								width: "93%",
								height: "auto",
								paddingTop: "0.3rem",
								paddingBottom: "0rem",
								marginLeft: "-1.3rem",
								marginTop: "10rem"
							}}>
							<video
								src="./img/tutorial-auto.mp4"
								controls
								playsInline
								style={{ width: "98.5%", height: "99.5%" }}
								poster="./img/capa-tutorial-auto.png"
							/>
						</div>
					</div>

					<div className="col-md-6 pl-md-5">
						<div className="item_info">
							Uma perícia com IA
							<h2>DETECTE AUTOMATICAMENTE ASSINATURAS</h2>
							<div className="item_info_counts">
								<div className="item_info_like">
									<h2 style={{ marginTop: "0.6rem" }}>🔎</h2>
								</div>
								<div className="item_info_like">
									<h2 style={{ marginTop: "0.6rem" }}>📄</h2>
								</div>
								<div className="item_info_like">
									<h2 style={{ marginTop: "0.6rem" }}>✓</h2>
								</div>
							</div>
							<p>
								Uma solução de detecção e verificação de assinaturas manuscritas criada
								com inteligência artificial avançada que utiliza redes neurais convolucionais (CNN)
								treinadas com milhares de imagens de assinaturas reais para detectá-las automaticamente
								em documentos e avaliar a similaridade entre elas.
							</p>
							<div className="spacer-20"></div>
							<h5>Desvende os contornos da autenticidade</h5>
							<div className="spacer-10"></div>
							<div className="de_tab">
								<div className="de_nav">
									{isLoggedIn ? (
										<Link
											to="/Verify"
											target="_self"
											rel="noopener noreferrer"
											style={{ textDecoration: "none" }}>
											<button className="btn-main">Verificar</button>
										</Link>
									) : (
										<Link
											onClick={login}
											target="_self"
											rel="noopener noreferrer"
											style={{ textDecoration: "none" }}>
											<button className="btn-main">Verificar</button>
										</Link>
									)}
								</div>
								<div className="spacer-20"></div>
								<div className="de_tab_content">
									<div className="tab-1 onStep fadeIn">
										<div className="p_list">
											<div className="p_list_pp">
												<span>
													<img
														className="lazy"
														src="./img/unlock.png"
														alt=""
													/>
												</span>
											</div>
											<div
												className="p_list_info"
												style={{ marginLeft: "-0.6rem" }}>
												<h5><b>Precisão:</b></h5>
												<span>
													<b></b> <p>A rede neural analisa minuciosamente cada traço, curva e pressão,
														utilizando uma combinação poderosa de algoritmos.</p>
												</span>
											</div>
										</div>
										<div className="p_list">
											<div className="p_list_pp">
												<span>
													<img
														className="lazy"
														src="./img/unlock.png"
														alt=""
													/>
												</span>
											</div>
											<div
												className="p_list_info"
												style={{ marginLeft: "-0.6rem" }}>
												<h5><b>Rapidez:</b></h5>
												<span>
													<b></b> <p>As ferramentas de IA processam a informação rapidamente,
														proporcionando resultados eficientes para uma tomada de decisão rápida. </p>
												</span>
											</div>
										</div>
										<div className="p_list">
											<div className="p_list_pp">
												<span>
													<img
														className="lazy"
														src="./img/unlock.png"
														alt=""
													/>
												</span>
											</div>
											<div
												className="p_list_info"
												style={{ marginLeft: "-0.6rem" }}>
												<h5><b>Facilidade:</b></h5>
												<span>
													<b></b> <p>O algoritmo realiza a detecção de assinaturas automaticamente ou,
														caso prefira, extraia manualmente as regiões de interesse.</p>
												</span>
											</div>
										</div>
										<div className="p_list">
											<div className="p_list_pp">
												<span>
													<img
														className="lazy"
														src="./img/unlock.png"
														alt=""
													/>
												</span>
											</div>
											<div
												className="p_list_info"
												style={{ marginLeft: "-0.6rem" }}>
												<h5><b>Relatórios:</b></h5>
												<span>
													<b></b> <p>Oferecemos um laudo completo, gerado automaticamente por IA,
														detalhando as análises e fornecendo insights valiosos para sua tomada de decisão. </p>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};
export default Benefit;
