import { createGlobalStyle } from "styled-components";
import { useAuth } from '../../AuthProvider';
import { Link } from "react-router-dom";
import React from "react";


const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #FAF6F1;
    border-bottom: solid 1px #ccc !important;
  }
`;


const Economy = function () {
	const { isLoggedIn, login } = useAuth();

	return (
		<div>
			<GlobalStyles />
			<section className="container">
				<div className="row mt-md-5 pt-md-4">
					<div
						className="col-md-6"
						style={{ paddingLeft: "2rem" }}>
						<div className="item_info">
							Decisões rápidas e eficientes
							<h2>ANTECIPE E ECONOMIZE</h2>
							<div className="item_info_counts">
								<div className="item_info_type">
									<i className="icon-layers">-</i>
								</div>
								<div className="item_info_like">
									<i className="icon-pricetags">$</i>
								</div>

								<div className="item_info_views">
									<i className="icon_lock-open_alt">+</i>
								</div>
							</div>
							<p>
								Evite gastos desnecessários com perícias! Nossa ferramenta funciona como uma
								‘análise preditiva’, fornecendo uma ‘probabilidade de falsidade’ e ajudando você a
								decidir se é necessário contratar uma perícia técnica.
							</p>
							<div className="spacer-40"></div>
							<h5>A verificação prévia por IA oferece diversos benefícios:</h5>
							<div className="spacer-20"></div>
							<div className="de_tab_content">
								<div className="tab-1 onStep fadeIn">
									<div className="p_list">
										<div className="p_list_pp">
											<span>
												<img
													className="lazy"
													src="./img/star-white.png"
													alt=""
												/>
											</span>
										</div>
										<div
											className="p_list_info"
											style={{ marginLeft: "-0.6rem" }}>
											<h5>
												<b>Economia:</b>
											</h5>
											<span>
												<b></b>{" "}
												<p>
													Dispensa a necessidade de uma perícia técnica dispendiosa em casos de
													baixa probabilidade de fraude.
												</p>
											</span>
										</div>
									</div>
									<div className="p_list">
										<div className="p_list_pp">
											<span>
												<img
													className="lazy"
													src="./img/star-white.png"
													alt=""
												/>
											</span>
										</div>
										<div
											className="p_list_info"
											style={{ marginLeft: "-0.6rem" }}>
											<h5>
												<b>Agilidade:</b>
											</h5>
											<span>
												<b></b>{" "}
												<p>
													Obtenha resultados rápidos e eficientes, otimizando seus processos de
													verificação.
												</p>
											</span>
										</div>
									</div>
									<div className="p_list">
										<div className="p_list_pp">
											<span>
												<img
													className="lazy"
													src="./img/star-white.png"
													alt=""
												/>
											</span>
										</div>
										<div
											className="p_list_info"
											style={{ marginLeft: "-0.6rem" }}>
											<h5>
												<b>Prevenção:</b>
											</h5>
											<span>
												<b></b>{" "}
												<p>
													{" "}
													Identifique assinaturas suspeitas em tempo real, protegendo sua empresa ou
													instituição.
												</p>
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className="de_nav">
							{isLoggedIn ? (
										<Link
											to="/Verify"
											target="_self"
											rel="noopener noreferrer"
											style={{ textDecoration: "none" }}>
											<button className="btn-main">Acessar</button>
										</Link>
									) : (
										<Link
											onClick={login}
											target="_self"
											rel="noopener noreferrer"
											style={{ textDecoration: "none" }}>
											<button className="btn-main">Acessar</button>
										</Link>
									)}
							</div>
						</div>
					</div>
					<div className="col-md-6 d-flex flex-column align-items-center">
						<div
							className="box-url"
							style={{
								width: "93%",
								height: "auto",
								paddingTop: "0.2rem",
								paddingBottom: "0.15rem",
								marginLeft: "4.4rem",
								marginTop: "2rem",
							}}>
							<video
								src="./img/tutorial-manual.mp4"
								controls
								playsInline
								style={{ width: "98.5%", height: "99.5%" }}
								poster="./img/capa-tutorial-manual.png"
							/>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};
export default Economy;
