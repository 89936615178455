
import create, { State } from "zustand";


export interface Region {
  id: number;
  box: [number, number, number, number];
  regionName?: string;
  image_url?: string;
  currentZone: 'signed' | 'noSigned' | 'anchor' | 'comparison';
}


export interface DetectionResult {
  image_size: [number, number];
  regions: Region[];
  image_url: string;
}


export interface ComparisonResult {
  anchor_image_base64: string;
  cosine_similarity: number;
  image: string;
  pressure1: number;
  pressure2: number;
  ssim: number;
  test_image_base64: string;
  regionId: number;
  image_id: number;
  anchor_image_id: number;
  comparison_region_id: number;
  regionName?: string;
}


export interface GeminiReport {
  Observacoes: string;
  AnaliseVisual: string;
  JustificativaDoResultado: string;
  Conclusao: string;
}


interface UserData {
  user_id: number;
  email: string | null;
  name: string | null;
  amount_paid: number;
  last_paid: Date;
  queries: number;
}


export interface SignatureDetectionState extends State {
  imageFile: File | null;
  isDetecting: boolean;
  detectionResult: DetectionResult | null;
  selectedRegion: Region | null;
  isCanvasOpen: boolean;
  setImageFile: (file: File | null) => void;
  setIsDetecting: (isDetecting: boolean) => void;
  setDetectionResult: (detectionResult: DetectionResult | null) => void;
  setSelectedRegion: (selectedRegion: Region | null) => void;
  setIsCanvasOpen: (isCanvasOpen: boolean) => void;
  removeRegion: (selectedRegion: Region) => void;
  autoDetect: boolean;
  setAutoDetect: (autoDetect: boolean) => void;
  anchorStatus: Record<number, boolean>;
  setAnchorStatus: (regionId: number, status: boolean) => void;
  checkStatus: Record<number, boolean>;
  setCheckStatus: (regionId: number, status: boolean) => void;
  updateRegionZone: (regionId: number, newZone: 'signed' | 'noSigned' | 'anchor' | 'comparison') => void;
  anchorChips: Region[];
  imageStep1Data: string | null;
  imageStep2Data: string | null;
  setImageStepData: (imageData: string | null, step: number) => void;
  comparisonDetails: any | null;
  comparisonResult: number | null;
  comparisonChips: Region[];
  setComparisonResults: (details: any, result: number | null, newComparisonChips: Region[], newReport: GeminiReport[] | null) => void;
  report: GeminiReport[] | null;
  setReport: (report: GeminiReport[] | null) => void;
  credits: number;
  setCredits: (credits: number) => void;
  user: UserData | null;
  setUser: (user: UserData | null) => void;
  loadedSignatures: Region[];
  setLoadedSignatures: (signatures: Region[]) => void;
  clearSignedRegions: () => void;
}


const useSignatureDetectionStore = create<SignatureDetectionState>((set, get) => ({
  imageFile: null,
  isDetecting: false,
  detectionResult: null,
  selectedRegion: null,
  isCanvasOpen: false,
  autoDetect: false,
  anchorStatus: {},
  checkStatus: {},
  anchorChips: [],
  comparisonChips: [],
  user: null,
  setUser: (userData: UserData | null) => set({ user: userData }),
  credits: 0,
  setCredits: (credits) => set({ credits }),
  report: [],
  setReport: (report: GeminiReport[] | null) => {
    console.log("Atualizando relatório na store:", report);
    set({ report });
  },
  setAutoDetect: (autoDetect: boolean) => set({ autoDetect }),
  setImageFile: (imageFile: File | null) => set({ imageFile }),
  setIsDetecting: (isDetecting: boolean) => set({ isDetecting }),
  setSelectedRegion: (selectedRegion: Region | null) => set({ selectedRegion }),
  setIsCanvasOpen: (isCanvasOpen: boolean) => set({ isCanvasOpen }),
  setDetectionResult: (detectionResult: DetectionResult | null) =>
    set((state) => ({
      detectionResult: detectionResult ? {
        ...detectionResult,
        image_url: state.imageFile ? URL.createObjectURL(state.imageFile) : '',
        regions: [
          ...(detectionResult.regions?.map(region => ({
            ...region,
            regionName: region.regionName || state.detectionResult?.regions?.find(r => r.id === region.id)?.regionName,
            image_url: region.image_url || (state.imageFile ? URL.createObjectURL(state.imageFile) : '')
          })) ?? []),
          ...state.detectionResult?.regions?.filter(existingRegion =>
            !detectionResult.regions?.some(newRegion => newRegion.id === existingRegion.id)
          ) ?? []
        ],
      } : null
    })),
  removeRegion: (removedRegion: Region) =>
    set((state) => {
      if (state.detectionResult) {
        const newRegions = state.detectionResult.regions.filter(
          (region) => region.id !== removedRegion.id
        );
        state.detectionResult = {
          ...state.detectionResult,
          regions: newRegions,
        };
      }
    }),
  updateRegionZone: (regionId: number, newZone: 'signed' | 'noSigned' | 'anchor' | 'comparison', callback?: () => void) => {
    set((state) => {
      if (state.detectionResult) {
        const updatedState = {
          detectionResult: {
            ...state.detectionResult,
            regions: state.detectionResult.regions.map((r: Region) =>
              r.id === regionId ? { ...r, currentZone: newZone } : r
            )
          }
        };
        if (callback) {
          callback();
        }
        return updatedState;
      }
      return state;
    });
  },
  setAnchorStatus: (regionId: number, status: boolean) =>
    set((state) => ({
      anchorStatus: { ...state.anchorStatus, [regionId]: status },
    })),
  setCheckStatus: (regionId: number, status: boolean) =>
    set((state) => ({
      checkStatus: { ...state.checkStatus, [regionId]: status },
    })),
  imageStep1Data: null,
  imageStep2Data: null,
  setImageStepData: (imageData: string | null, step: number) => {
    if (step === 1) {
      set({ imageStep1Data: imageData });
    } else if (step === 2) {
      set({ imageStep2Data: imageData });
    }
  },
  comparisonDetails: null,
  comparisonResult: null,
  setComparisonResults: (details, result, newComparisonChips, newReport) =>
    set({
      comparisonDetails: details,
      comparisonResult: result,
      comparisonChips: newComparisonChips,
      report: newReport || [],
    }),
  loadedSignatures: [],
  setLoadedSignatures: (signatures: Region[]) => set({ loadedSignatures: signatures }),
  clearSignedRegions: () =>
    set((state) => {
      if (state.detectionResult) {
        const updatedRegions = state.detectionResult.regions.filter(
          (region) => region.currentZone !== "signed"
        );
        return {
          detectionResult: {
            ...state.detectionResult,
            regions: updatedRegions,
          }
        };
      } else {
        return state;
      }
    }),

}));

export default useSignatureDetectionStore;