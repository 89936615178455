import { createTheme } from "@material-ui/core/styles";
import { Shadows } from "@material-ui/core/styles/shadows"

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#00999b",
      light: '#ffa5a5',
      dark: '#9be9ff',
      contrastText: '#000',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#FF7F50',
      light: '#2796b2',
      dark: '#ff5d00',
      contrastText: '#000',
    },
    background: {
      default: "#fff",
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.97)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    type: "light",
  },
  shadows: Array(25).fill("none") as Shadows,
});

export default theme;
