import {
	Box,
	Card,
	Container,
	CircularProgress,
	Grid,
	makeStyles,
	Snackbar,
	Theme,
	Typography,
} from "@material-ui/core";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Fingerprint from "@material-ui/icons/Fingerprint";
import Divider from "@mui/material/Divider";
import React, { useState, useRef } from "react";
import useSignatureDetectionStore, { Region, ComparisonResult } from "./store";
import { CARD_MIN_HEIGHT } from "./SignatureLoader";
import DetectionDownload from "./DetectionDownload";
import SignatureChip from "./SignatureChip";
import CanvasToggler from "./CanvasToggler";
import SaveDialog from "../SaveDialog";
import InfoDialog from "../InfoDialog";


// Estilos do componente
const useStyles = makeStyles((theme: Theme) => ({
	card: {
		minHeight: CARD_MIN_HEIGHT,
		minWidth: 630,
		position: "relative",
		textAlign: "center",
		padding: "0px 10",
		color: "#a2a2a2",
		background: "#efefef",
		borderRadius: 0,
		transition: ".3s",
		"&:hover": {
			transition: ".3s",
		},
		"&.left": {
			textAlign: "center",
		},
	},

	// Estilos para o grupo de chips
	chipsGroup: {
		"& > span > *": {
			margin: theme.spacing(0.5),
			display: "inline-flex",
		},
		minHeight: CARD_MIN_HEIGHT / 4,
		width: "100%",
		position: "relative",
		textAlign: "center",
		padding: "10px 0",
		margin: "20px 0",
		color: "#a2a2a2",
		border: "dashed 1px #888",
		background: "#FDFBF8",
		display: "block",
		borderRadius: 0,
		transition: ".3s",
		"&:hover": {
			transition: ".3s",
		},
		"&.left": {
			textAlign: "center",
		},
	},

	// Estilos para a seleção de chips
	chipsSelect: {
		"& > span > *": {
			margin: theme.spacing(0.5),
			display: "inline-flex",
		},
		minHeight: CARD_MIN_HEIGHT / 4,
		width: "100%",
		position: "relative",
		textAlign: "center",
		padding: "10px 0",
		margin: "20px 0",
		color: "#a2a2a2",
		background: "#FDFBF8",
		display: "block",
		borderRadius: 0,
		transition: ".3s",
		"&:hover": {
			transition: ".3s",
		},
		"&.left": {
			textAlign: "center",
		},
	},

	// Estilos para salvar chips
	chipsSave: {
		"& > span > *": {
			margin: theme.spacing(0.5),
			display: "inline-flex",
		},
		minHeight: CARD_MIN_HEIGHT / 4,
		width: "100%",
		position: "relative",
		textAlign: "center",
		padding: "10px 0",
		margin: "20px 0",
		color: "#a2a2a2",
		border: "dashed 1px #888",
		background: "#FDFBF8",
		display: "block",
		borderRadius: 0,
		transition: ".3s",
		"&:hover": {
			transition: ".3s",
		},
		"&.left": {
			textAlign: "center",
		},
	},

	// Estilos para chips de âncora
	chipsAnchor: {
		minHeight: CARD_MIN_HEIGHT / 6,
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexWrap: "wrap",
	},
}));

// Componente de alerta customizado
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
	props,
	ref,
) {
	return <MuiAlert elevation={6} ref={ref} variant="outlined" {...props} />;
});

// Componente principal
export default function DetectionResultCard({ setShowReport }: { setShowReport: (show: boolean) => void }) {
	const classes = useStyles();

	// Estados do componente
	const [draggedRegion, setDraggedRegion] = useState<Region | null>(null);
	const [openDialog, setOpenDialog] = useState(false);
	const [openAlert, setOpenAlert] = useState(false);
	const [alertData, setAlertData] = useState<{ title: string; content: string; type: "info" | "alert" | "error" } | null>(null);
	const [snackbarData, setSnackbarData] = useState<{ message: string; severity: 'success' | 'error' | 'warning' | 'info' } | null>(null);
	const [fileName, setFileName] = useState<string>("");
	const [currentRegion, setCurrentRegion] = useState<Region | null>(null);
	const [regionNames, setRegionNames] = useState<Record<number, string | undefined>>({});
	const [regionBeingSaved, setRegionBeingSaved] = useState<Region | null>(null);
	const [comparisonChips, setComparisonChips] = useState<Region[]>([]);
	const [comparisonDetails, setComparisonDetails] = useState<any>(null);
	const [anchorChips, setAnchorChips] = useState<Region[]>([]);
	const [isUploading, setIsUploading] = useState(false);

	// Dados do store
	const imageFile = useSignatureDetectionStore((state) => state.imageFile);
	const userId = useSignatureDetectionStore((state) => state.user?.user_id);
	const email = useSignatureDetectionStore((state) => state.user?.email);
	const detectionResult = useSignatureDetectionStore((state) => state.detectionResult);
	const setDetectionResult = useSignatureDetectionStore((state) => state.setDetectionResult);
	const setCheckStatus = useSignatureDetectionStore((state) => state.setCheckStatus);
	const updateRegionZone = useSignatureDetectionStore((state) => state.updateRegionZone);
	const setImageStepData = useSignatureDetectionStore((state) => state.setImageStepData);
	const setComparisonResults = useSignatureDetectionStore((state) => state.setComparisonResults);
	const setCredits = useSignatureDetectionStore((state) => state.setCredits);
	const removeRegion = useSignatureDetectionStore((state) => state.removeRegion);

	// Refs para armazenar as imagens de cada etapa
	const imageStep1Ref = useRef<HTMLImageElement>(null);
	const imageStep2Ref = useRef<HTMLImageElement>(null);

	// Função para exibir a imagem da etapa no frontend
	const displayImageStep = (imageData: string | null, step: number) => {
		if (imageData) {
			const imageRef = step === 1 ? imageStep1Ref : imageStep2Ref;
			if (imageRef.current) {
				imageRef.current.src = `data:image/png;base64,${imageData}`;
			}
		}
	};

	// Manipuladores de eventos de arrastar e soltar
	const dragStartHandler = (region: Region) => {
		setDraggedRegion(region);
	};

	// Manipuladores de eventos de diálogo
	const handleOpenDialog = () => {
		setFileName("");
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		setCurrentRegion(null);
		setRegionBeingSaved(null);
	};

	// Manipuladores de eventos de alerta
	const handleOpenAlert = (data: { title: string; content: string; type: "info" | "alert" | "error" }) => {
		setAlertData(data);
		setOpenAlert(true);
	};

	const handleCloseAlert = () => {
		setOpenAlert(false);
		setAlertData(null);
	};

	// Manipuladores de eventos de chip
	const handleRemoveComparisonChip = (regionId: number) => {
		setComparisonChips((prevChips) => prevChips.filter((chip) => chip.id !== regionId));
	};

	const handleRemoveAnchorChip = (regionId: number) => {
		setAnchorChips((prevChips) => prevChips.filter((chip) => chip.id !== regionId));
	};

	// Manipulador de eventos de snackbar
	const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackbarData(null);
	};


	// Manipulador de eventos de soltar
	const dropHandler = (event: React.DragEvent<HTMLElement>, targetZone: "signed" | "noSigned") => {
		event.preventDefault();
		if (draggedRegion) {
			// Bloquear arrasto de No Signed para Signed
			if (draggedRegion.currentZone === "noSigned" && targetZone === "signed") {
				handleOpenAlert({
					title: "Atenção!",
					content: "A assinatura já está salva.",
					type: "error",
				});
				return;
			}

			// Chama handleOpenDialog quando a zona de destino for 'noSigned'
			if (targetZone === "noSigned") {
				setRegionBeingSaved(draggedRegion);
				handleOpenDialog();
			}
		}
		setDraggedRegion(null);
	};


	// Função para obter a URL da imagem da assinatura
	const getSignatureImageDataUrl = async (region: Region): Promise<string> => {

		// 1. Verifica se a região já possui uma URL de imagem
		if (region.image_url) {
			return Promise.resolve(region.image_url);
		}

		// 2. Se a região não possui uma URL de imagem, usa a lógica anterior
		if (!detectionResult || !imageFile) {
			return Promise.reject("Dados da detecção ou arquivo de imagem não encontrados.");
		}

		if (!detectionResult || !imageFile) {
			return Promise.reject("Dados da detecção ou arquivo de imagem não encontrados.");
		}

		return new Promise<string>((resolve, reject) => {
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");

			if (!ctx) {
				reject("Impossível obter o contexto 2D do canvas.");
				return;
			}

			canvas.width = detectionResult.image_size[0];
			canvas.height = detectionResult.image_size[1];

			const img = new Image();
			img.src = URL.createObjectURL(imageFile);

			img.onload = () => {
				ctx.drawImage(img, 0, 0);

				const signature = ctx.getImageData(
					region.box[0],
					region.box[1],
					region.box[2],
					region.box[3],
				);

				// 3. Criar um canvas com fundo transparente
				const signatureCanvas = document.createElement("canvas");
				signatureCanvas.width = signature.width;
				signatureCanvas.height = signature.height;
				const signatureCtx = signatureCanvas.getContext("2d")!;

				// 4. Definir o fundo como transparente
				signatureCtx.clearRect(0, 0, signatureCanvas.width, signatureCanvas.height);

				// 5. Desenhar a assinatura no canvas
				signatureCtx.putImageData(signature, 0, 0);

				// 6. Codificar em Base64
				const signatureDataUrl = signatureCanvas.toDataURL("image/png");
				resolve(signatureDataUrl);
			};
			img.onerror = (error) => {
				reject(`Erro ao carregar a imagem: ${error}`);
			};
		});
	};


	// Função para salvar a assinatura no servidor
	const saveSignatureToServer = async (region: Region, fileName: string): Promise<Response> => {
		if (detectionResult && imageFile) {
			try {
				// Cria um canvas para desenhar a imagem original
				const canvas = document.createElement("canvas");
				const ctx = canvas.getContext("2d")!;

				canvas.width = detectionResult.image_size[0];
				canvas.height = detectionResult.image_size[1];

				const img = new Image();
				img.src = URL.createObjectURL(imageFile);
				await new Promise((resolve) => {
					img.onload = resolve;
				});
				ctx.drawImage(img, 0, 0);

				// Extrai a assinatura da imagem original
				const signature = ctx.getImageData(
					region.box[0],
					region.box[1],
					region.box[2],
					region.box[3],
				);

				// Cria um novo canvas para a assinatura extraída
				const signatureCanvas = document.createElement("canvas");
				const signatureCtx = signatureCanvas.getContext("2d")!;
				signatureCanvas.width = signature.width;
				signatureCanvas.height = signature.height;
				signatureCtx.putImageData(signature, 0, 0);

				// Converte a assinatura para Data URL
				const signatureDataUrl = signatureCanvas.toDataURL("image/png");

				// Envia a assinatura para o servidor
				const response = await fetch("/api/save-signature", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						image: signatureDataUrl,
						fileName: fileName,
						box: region.box,
						regionId: region.id,
						user_id: userId,
					}),
				});

				// Exibe mensagens de sucesso ou erro
				if (response.ok) {
					setSnackbarData({ message: `Assinatura ${fileName} salva com sucesso.`, severity: 'success' });
				} else {
					setSnackbarData({ message: `Erro ao salvar a assinatura ${fileName}. Tente mais tarde.`, severity: 'error' });
					console.error("Erro ao salvar assinatura no servidor:", response.status);
				}
				return response;
			} catch (error) {
				console.error("Erro ao salvar assinatura:", error);
				return Promise.reject(error);
			}
		} else {
			return Promise.reject(new Error("Dados da detecção ou arquivo de imagem não encontrados."));
		}
	};


	// Função para gerar e salvar o arquivo JSON
	const generateAndSaveJsonFile = async (region: Region, fileName: string) => {
		if (imageFile && detectionResult) {
			try {
				// Cria o objeto JSON com os dados da região
				const jsonData = {
					image_size: detectionResult.image_size,
					region: { ...region, id: region.id },
				};

				// Envia o JSON para o servidor
				const response = await fetch("/api/save-json", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						data: jsonData,
						fileName: fileName,
						regionId: region.id,
						user_id: userId,
					}),
				});

				// Verifica se a requisição foi bem-sucedida
				if (response.ok) {
					// ...
				} else {
					console.error("Erro ao salvar JSON no servidor:", response.status);
				}
			} catch (error) {
				console.error("Erro ao salvar JSON no servidor:", error);
			}
		}
	};


	// Função para lidar com o evento de salvar a assinatura
	const handleSave = async (fileName: string) => {
		// Valida o nome do arquivo
		if (fileName.trim() === "") {
			handleOpenAlert({
				title: "Nome de arquivo inválido!",
				content: "Por favor, insira um nome para o arquivo.",
				type: "alert",
			});
			return;
		}

		// Verifica se a região está sendo salva e se os resultados da detecção estão disponíveis
		if (regionBeingSaved !== null && detectionResult) {
			// Encontra o índice da região na lista de regiões
			const regionIndex = detectionResult.regions.findIndex(
				(region) => region.id === regionBeingSaved.id,
			);

			if (regionIndex !== undefined && regionIndex !== -1) {
				// Atualiza a região com o nome e a move para "No Signed"
				const updatedRegions = [...detectionResult.regions];
				updatedRegions[regionIndex] = {
					...updatedRegions[regionIndex],
					currentZone: "noSigned",
					regionName: fileName,
				};

				// Atualiza a zona da região no store
				updateRegionZone(regionBeingSaved.id, "noSigned");

				// Atualiza o estado com a região modificada
				setDetectionResult({
					...detectionResult,
					regions: updatedRegions.map((r) =>
						r.id === regionBeingSaved.id ? { ...r, currentZone: 'noSigned', regionName: fileName } : r,
					),
					image_size: detectionResult.image_size,
				});

				try {
					// Salva a assinatura e o JSON no servidor
					const signatureResponse = await saveSignatureToServer(
						updatedRegions[regionIndex],
						fileName,
					);

					const signatureData = await signatureResponse.json();
					const regionId = signatureData.regionId;

					await generateAndSaveJsonFile(updatedRegions[regionIndex], regionId);

					updateRegionZone(regionBeingSaved.id, "noSigned");

				} catch (error) {
					console.error("Erro ao salvar assinatura ou JSON:", error);
				}
			}
		}
		// Fecha o diálogo e limpa o estado
		handleCloseDialog();
		setRegionBeingSaved(null);
	};


	// Função para lidar com a exclusão de uma região
	const handleDeleteRegion = async (region: Region) => {
		try {
			// Remove os arquivos do servidor
			const response = await fetch(`/api/delete-signature/${region.id}?user_id=${userId}`, {
				method: "DELETE",

			});

			if (response.ok) {
				// Remove a região do estado global
				removeRegion(region);

				// Remove a região da zona de comparação
				setComparisonChips((prevChips) => prevChips.filter((chip) => chip.id !== region.id));

				// Remove a região da zona de âncoras
				setAnchorChips((prevChips) => prevChips.filter((chip) => chip.id !== region.id));

				setSnackbarData({ message: 'A assinatura foi excluída com sucesso!', severity: 'success' });

			} else {
				console.error(`Erro ao deletar arquivos da região ${region.id}:`, response.status);
				const errorData = await response.json();
				console.error("Detalhes do erro:", errorData);

				setSnackbarData({ message: 'Erro ao excluir a assinatura. Tente mais tarde.', severity: 'error' });
			}
		} catch (error) {
			console.error(`Erro ao deletar arquivos da região ${region.id}:`, error);
		}
	};


	// Função para lidar com o evento de soltar na zona de comparação
	const handleDropOnComparison = async (event: React.DragEvent<HTMLElement>) => {
		event.preventDefault();
		if (draggedRegion) {

			// Impede adicionar a mesma região duas vezes
			if (comparisonChips.some((chip) => chip.id === draggedRegion.id)) {
				handleOpenAlert({
					title: "Ops!",
					content: "Esta assinatura já está na zona de comparação.",
					type: "info",
				});
				return;
			}

			// Alerta se tentar arrastar de "Signed"
			if (draggedRegion.currentZone === "signed") {
				handleOpenAlert({
					title: "Atenção!",
					content: "Adicione a assinatura em 'Salvas' antes de comparar.",
					type: "info",
				});
				return;
			}

			// Alerta se já houver uma região na zona de comparação
			if (comparisonChips.length >= 1) {
				handleOpenAlert({
					title: "Limite atingido!",
					content: "A zona de comparação pode conter apenas uma assinatura.",
					type: "info",
				});
				return;
			}

			// Adiciona a região à zona de comparação
			setComparisonChips((prevChips) => [
				...prevChips,
				{ ...draggedRegion, regionName: draggedRegion.regionName },
			]);

			const regions = detectionResult?.regions || [];

			try {
				// Extrai as assinaturas do servidor
				const response = await fetch("/api/extract-signatures/", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						regionId: draggedRegion.id,
						regions: regions,
						user_id: userId,
					}),
				});

				if (response.ok) {
					const data = await response.json();
					// ...
				} else {
					const errorData = await response.json();
					console.error(
						`Erro ao extrair assinaturas da região ${draggedRegion.id}:`,
						response.status,
						errorData
					);
				}
			} catch (error) {
				console.error(
					`Erro ao processar assinatura para comparação da região ${draggedRegion.id}:`,
					error,
				);
			}
			setDraggedRegion(null);
		}
	};


	// Função para lidar com o evento de soltar na zona de âncoras
	const handleDropOnAnchor = async (event: React.DragEvent<HTMLElement>) => {
		event.preventDefault();
		if (draggedRegion) {

			// Verifica se o limite de âncoras foi atingido
			if (anchorChips.length >= 3) {
				handleOpenAlert({
					title: "Limite atingido!",
					content: "A caixa 'Lista' pode conter no máximo 3 assinaturas.",
					type: "info",
				});
				return;
			}

			// Impede adicionar a mesma região duas vezes
			if (anchorChips.some((chip) => chip.id === draggedRegion.id)) {
				handleOpenAlert({
					title: "Ops!",
					content: "Esta assinatura já está na lista.",
					type: "info",
				});
				return;
			}

			// Alerta se tentar arrastar de "Signed"
			if (draggedRegion.currentZone === "signed") {
				handleOpenAlert({
					title: "Atenção!",
					content: "Adicione a assinatura em 'Salvas' antes de adicioná-la à lista.",
					type: "info",
				});
				return;
			}

			// Adiciona a região à lista de âncoras
			setAnchorChips((prevChips) => [...prevChips, draggedRegion]);

			// Obter a lista completa de regiões do estado
			const regions = detectionResult?.regions || [];

			try {
				// Extrai as assinaturas do servidor
				const response = await fetch("/api/extract-signatures/", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						regionId: draggedRegion.id,
						regions: regions,
						user_id: userId,
					}),
				});

				if (response.ok) {
					const data = await response.json();
					// ...
				} else {
					const errorData = await response.json();
					console.error(
						`Erro ao extrair assinaturas da região ${draggedRegion.id}:`,
						response.status,
						errorData
					);
					// Remove o chip da lista de âncoras em caso de erro
					setAnchorChips((prevChips) => prevChips.filter((chip) => chip.id !== draggedRegion.id));
				}
			} catch (error) {
				console.error(`Erro ao extrair assinaturas da região ${draggedRegion.id}:`, error);
				// Remove o chip da lista de âncoras em caso de erro
				setAnchorChips((prevChips) => prevChips.filter((chip) => chip.id !== draggedRegion.id));
			}
		}
	};

	// Função para lidar com a comparação de assinaturas
	const handleCompareSignatures = async () => {

		// Define o estado de upload como true
		setIsUploading(true);

		// Limpa os resultados de comparação anteriores
		setComparisonResults(null, null, [], null);

		// Verifica se há uma assinatura na zona de comparação
		if (comparisonChips.length === 1) {
			try {
				const region = comparisonChips[0];
				const anchorRegionIds = anchorChips.map(chip => chip.id);

				// Verifica se a região não está na zona "signed"
				if (region.currentZone !== "signed") {
					const imageDataUrl = await getSignatureImageDataUrl(region);

					if (anchorRegionIds) {
						// Obter a lista completa de regiões do estado
						const regions = detectionResult?.regions || [];

						// Envia a requisição para o servidor para comparar as assinaturas
						const response = await fetch("/api/verify-signatures/", {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify({
								image: imageDataUrl,
								regionId: region.id,
								regions: regions,
								anchorRegionIds: anchorRegionIds,
								user_id: userId,
								email: email,
							}),
						});

						if (response.ok) {
							const data = await response.json();

							// Atualiza os créditos do usuário
							const userResponse = await fetch('/api/data/user');
							const userData = await userResponse.json();
							setCredits(userData.credits);

							// Exibe o custo da operação
							if (data.cost >= 0) {
								setSnackbarData({ message: `Operação realizada com sucesso. Custo: ${data.cost} crédito(s).`, severity: 'success' });
							}

							// Define os resultados da comparação
							if (data.details) {
								setComparisonDetails(data.details);
								setComparisonResults(data.details, data.similarity, comparisonChips, data.report);
								setShowReport(true);

								// Exibe as imagens das etapas
								data.details.results.forEach((result: ComparisonResult) => {
									if (result.test_image_base64) {
										displayImageStep(result.test_image_base64, 2);
										setImageStepData(result.test_image_base64, 2);
									} else {
									}

									if (result.anchor_image_base64) {
										displayImageStep(result.anchor_image_base64, 1);
										setImageStepData(result.anchor_image_base64, 1);
									} else {
									}
								});
								setCheckStatus(region.id, true);
							} else {
								console.error("A resposta da API não contém a propriedade 'details'.");
							}
						} else {
							const errorData = await response.json();
							console.error("Erro na requisição:", response.status, errorData);

							// Verifica se o erro é de crédito insuficiente
							if (errorData.error === "Créditos insuficientes") {
								setSnackbarData({
									message: "Ops, Créditos insuficientes!",
									severity: "error",
								});
							} else {
								setSnackbarData({
									message: `Erro ao comparar assinaturas: ${errorData.error || errorData.message || "Erro desconhecido"}`,
									severity: "error",
								});
							}
						}
					}
				}
			} catch (error) {
				console.error("Erro ao verificar assinatura:", error);
				handleOpenAlert({
					title: "Erro!",
					content: "Ocorreu um erro ao verificar a assinatura. Por favor, tente novamente mais tarde.",
					type: "error",
				});
			}
		} else {
			handleOpenAlert({
				title: "Atenção!",
				content: "Selecione apenas uma assinatura para comparação.",
				type: "alert",
			});
		}
		// Define o estado de upload como false
		setIsUploading(false);
		// Exibe o relatório
		setShowReport(true);
		// Limpa as zonas 'anchor' e 'comparison'
		setAnchorChips([]);
		setComparisonChips([]);
	};

	// Retorno do componente (JSX)
	return (
		<Card className={classes.card}>
			<Container>

				<Box component="div" mt={3} />

				<DetectionDownload />

				<Box component="div" mt={3} />

				{/* Componente para alternar a visualização do canvas */}
				<CanvasToggler />

				<Divider />

				{/* Zona para assinaturas selecionadas ("signed") */}
				<Box
					component="div"
					mt={1}
					data-testid="true-zone"
					className={classes.chipsSelect}
					onDrop={(event) => dropHandler(event, "signed")}
					onDragOver={(event) => event.preventDefault()}
				>
					<Typography variant="h6">Selecionadas</Typography>
					<Box component="div" mt={1} />
					{/* Renderiza os chips das assinaturas selecionadas */}
					{detectionResult
						? detectionResult.regions
							.filter((region) => region.currentZone === "signed")
							.map((region: Region, index) => (
								<SignatureChip
									key={`${region.id}-${region.currentZone}-${region.regionName}`}
									region={region}
									dragStartHandler={dragStartHandler}
									onDelete={() => handleDeleteRegion(region)}
									regionName={regionNames[index] || undefined}
									showSavedIcon={!!region.regionName}
									detectionResult={detectionResult}
									currentZone="signed"
								/>
							))
						: null}
				</Box>

				<Divider />

				{/* Zona para assinaturas salvas ("noSigned") */}
				<Box
					component="div"
					data-testid="false-zone"
					mt={1}
					className={classes.chipsSave}
					onDrop={(event) => dropHandler(event, "noSigned")}
					onDragOver={(event) => event.preventDefault()}
				>
					<Typography style={{ color: "#00999b" }} variant="h6">
						Salvas
					</Typography>
					{/* Renderiza os chips das assinaturas salvas */}
					<Box data-testid="noSigned-zone" className={classes.chipsAnchor}>
						{detectionResult &&
							detectionResult.regions.some((region) => region.currentZone === "noSigned") ? (
							detectionResult.regions
								.filter((region) => region.currentZone === "noSigned")
								.map((region: Region, index) => (
									<SignatureChip
										key={`${region.id}-${region.currentZone}-${region.regionName}`}
										region={region}
										dragStartHandler={dragStartHandler}
										onDelete={() => handleDeleteRegion(region)}
										showSavedIcon={!!region.regionName}
										detectionResult={detectionResult}
										currentZone="noSigned"
										regionName={region.regionName}
									/>
								))
						) : (
							<Typography variant="body2">
								Arraste a assinatura selecionada aqui para começar.
							</Typography>
						)}
					</Box>
				</Box>

				<Divider />

				{/* Grid para as zonas de "Lista" e "Âncora" */}
				<Grid container spacing={2}>
					{/* Zona para assinaturas de referência ("Lista") */}
					<Grid item xs={12} sm={6}>
						<Box
							component="div"
							mt={1}
							className={classes.chipsGroup}
							onDrop={handleDropOnAnchor}
							onDragOver={(event) => event.preventDefault()}
						>
							<Typography style={{ color: "#ff8787" }} variant="h6">
								Lista
							</Typography>
							{/* Renderiza os chips das assinaturas de referência */}
							<Box className={classes.chipsAnchor} data-testid="anchor-zone">
								{anchorChips.length === 0 ? (
									<Typography variant="body2">
										Arraste as assinaturas de referência aqui.
									</Typography>
								) : (
									anchorChips.map((region) => (
										<SignatureChip
											key={`${region.id}-${region.currentZone}-${region.regionName}`}
											region={region}
											dragStartHandler={() => { }}
											onDelete={() => handleRemoveAnchorChip(region.id)}
											detectionResult={detectionResult}
											currentZone="anchor"
										/>
									))
								)}
							</Box>
						</Box>
					</Grid>

					<Divider />

					{/* Zona para assinatura a ser comparada ("Âncora") */}
					<Grid item xs={12} sm={6}>
						<Box
							component="div"
							mt={1}
							className={classes.chipsGroup}
							onDrop={handleDropOnComparison}
							onDragOver={(event) => event.preventDefault()}
						>
							<Typography style={{ color: "#3dbfe2" }} variant="h6">
								Âncora
							</Typography>
							{/* Renderiza o chip da assinatura a ser comparada */}
							<Box className={classes.chipsAnchor} data-testid="comparison-zone">
								{comparisonChips.length === 0 ? (
									<Typography variant="body2">
										Arraste a assinatura a ser comparada aqui.
									</Typography>
								) : (
									comparisonChips.map((region) => (
										<SignatureChip
											key={`${region.id}-${region.currentZone}-${region.regionName}`}
											region={region}
											dragStartHandler={() => { }}
											onDelete={() => handleRemoveComparisonChip(region.id)}
											detectionResult={detectionResult}
											currentZone="comparison"
										/>
									))
								)}
							</Box>
						</Box>
					</Grid>

					{/* Botão para comparar as assinaturas */}
					<Grid item xs={12}>
						<label htmlFor="button-compare">
							<button
								className={`btn-sign ${anchorChips.length === 0 || comparisonChips.length === 0 ? "disabled" : ""
									}`}
								onClick={handleCompareSignatures}
							>
								<Fingerprint aria-label="fingerprint" fontSize="small" className="upload-icon" />
								{/* Exibe um indicador de progresso durante o upload */}
								{isUploading ? (
									<CircularProgress size={24} color="inherit" />
								) : (
									"Comparar"
								)}
							</button>
						</label>
						<Box component="div" mt={3} />
					</Grid>
				</Grid>

				{/* Snackbar para exibir mensagens de sucesso ou erro */}
				{snackbarData && (
					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={!!snackbarData}
						autoHideDuration={4000}
						onClose={handleCloseSnackbar}
					>
						<Alert onClose={handleCloseSnackbar} severity={snackbarData.severity} sx={{ width: "100%" }}>
							{snackbarData.message}
						</Alert>
					</Snackbar>
				)}

				{/* Diálogo para salvar a assinatura */}
				<SaveDialog isOpen={openDialog} onSave={(fileName) => handleSave(fileName)} onCancel={handleCloseDialog} />

				{/* Diálogo para exibir informações */}
				<InfoDialog
					data-testid="info-dialog"
					isOpen={openAlert}
					title={alertData?.title || ""}
					content={alertData?.content || ""}
					type={alertData?.type || "info"}
					onCancel={handleCloseAlert}
				/>
			</Container>
		</Card>
	);
}