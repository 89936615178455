
import {
	Box,
	Grid,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Paper,
	Typography,
	useTheme,
} from "@material-ui/core";
import { jsPDF } from "jspdf";
import React, { useRef } from "react";
import html2canvas from "html2canvas";
import { Region } from './store';
import { GeminiReport } from "./store";
import { ComparisonResult } from "./store";
import { CARD_MIN_HEIGHT } from "./SignatureLoader";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";


const useStyles = makeStyles((theme: Theme) => ({
	muiGrid: {
		minHeight: CARD_MIN_HEIGHT,
		minWidth: 1350,
	},
	muiGrid2: {
		minHeight: CARD_MIN_HEIGHT,
		minWidth: 1080,
		display: "flex",
		flexDirection: "column",
		width: "100vw",
		height: "100vh",
		transition: ".3s",
		"&:hover": {
			transition: ".3s",
		},
		"&.left": {
			textAlign: "center",
		},
	},
	textTop: {
		minHeight: 100,
		position: "relative",
		textAlign: "center",
		padding: "0px 0",
		marginBottom: "20px",
		color: "#a2a2a2",
		background: "#EFEFEF",
		borderRadius: 0,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	tableContainer: {
		marginTop: "10px",
		background: "#fffcf9",
	},
	percentage: {
		fontWeight: "bold",
	},
	// Estilo para a célula da tabela explicativa
	explanatoryCell: {
		border: 0,
		padding: theme.spacing(2),
		textAlign: "left",
		background: "#f4f0e8",
	},
	// Estilo para a lista dentro da célula explicativa
	explanatoryList: {
		padding: "15px",
		margin: "0px",
		border: 0,
	},
	text: {
		fontWeight: "bold",
		textAlign: "center",
		marginTop: "20px",
		marginBottom: 0,

	},
	pdf: {
		padding: "40px",
		margin: "20px",
	},
}));


interface DetailedReportProps {
	comparisonResult: number | null;
	comparisonDetails: any;
	comparisonChips: Region[];
	regionNames: string[];
	report: GeminiReport[] | null;
	id: string;
}


const calcularDiferencaPercentual = (valor1: number, valor2: number): string => {
	const maiorValor = Math.max(valor1, valor2);
	const diferenca = Math.abs(valor2 - valor1);
	const percentual = (diferenca / maiorValor) * 100;
	return `${percentual.toFixed(2)}%`;
};


const DetailedReport: React.FC<DetailedReportProps> = ({
	comparisonResult,
	comparisonDetails,
	comparisonChips,
	regionNames,
	report,
	id,
}) => {

	const currentDate = new Date().toLocaleDateString();
	const reportRef = useRef<HTMLDivElement>(null);
	const classes = useStyles();
	const theme = useTheme();
	const detailedReportRef = useRef<HTMLDivElement>(null);


	const getSimilarityColor = (similarity: number): string => {
		if (similarity >= 98) {
			return theme.palette.primary.main;
		} else if (similarity >= 87) {
			return theme.palette.secondary.light;
		} else {
			return theme.palette.error.main;
		}
	};


	const handleDownloadReport = async () => {
		if (reportRef.current) {
			const canvas = await html2canvas(reportRef.current);
			const dataURL = canvas.toDataURL("image/jpeg");

			const doc = new jsPDF();

			const margin = 10;
			const imgWidth = 210 - 2 * margin;
			const imgHeight = (canvas.height * imgWidth) / canvas.width;
			doc.addImage(dataURL, "JPEG", margin, margin, imgWidth, imgHeight);

			// Carregar a imagem da logo (ajuste o caminho da imagem)
			const logoImg = new Image();
			logoImg.src = './img/logo-veredito-ink-1000.png';

			// Esperar a logo carregar
			await new Promise((resolve, reject) => {
				logoImg.onload = resolve;
				logoImg.onerror = reject;
			});

			// Definir largura da logo (ajuste conforme necessário)
			const logoWidth = 50;
			const logoHeight = (logoImg.height * logoWidth) / logoImg.width;

			// Calcular posição x para centralizar a logo
			const logoX = (doc.internal.pageSize.getWidth() - logoWidth) / 2;

			// Calcular posição y para exibir a logo na parte inferior com margem
			const logoY = doc.internal.pageSize.getHeight() - logoHeight - margin;

			// Adicionar logo ao PDF
			doc.addImage(logoImg, 'PNG', logoX, logoY, logoWidth, logoHeight);

			// Adicionar texto abaixo da logo
			const text = 'vereditoink.soilore.com';
			const trimmedText = text.trim();

			// Criar um elemento span temporário
			const span = document.createElement('span');
			span.style.fontSize = '8px'; // Definir o tamanho da fonte
			span.textContent = trimmedText;
			document.body.appendChild(span); // Adicionar ao DOM

			// Calcular a largura do texto em pixels
			const textWidthPx = span.offsetWidth;

			// Remover o span do DOM
			document.body.removeChild(span);

			// Converter a largura para milímetros (considerando 72 DPI)
			const textWidthMm = textWidthPx / 72 * 25.4;

			// Centralizar o texto horizontalmente
			const pageCenterX = doc.internal.pageSize.getWidth() / 2;
			const textX = pageCenterX - (textWidthMm / 2) + 2;

			const textY = logoY + logoHeight + 2;

			doc.setFontSize(8);
			doc.text(trimmedText, textX, textY);

			doc.save(`Relatorio_Assinaturas_${currentDate}.pdf`);
		}
	};


	return (
		<div ref={detailedReportRef}>
			<div ref={reportRef} >
				{" "}
				<Grid container >
					{/* Coluna 1: Dados do Relatório */}
					<Grid item xs={12} className={classes.text}>
						<Typography variant="h4" color="textPrimary" component="strong">
							<strong>Relatório Detalhado da Comparação</strong>
						</Typography>
					</Grid>
					<Grid item xs={12} md={4}>
						<TableContainer component={Paper} className={classes.tableContainer}>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell className={classes.explanatoryCell}>
											<Typography variant="subtitle1">
												<strong>Dados do Relatório:</strong>
											</Typography>
											<ul className={classes.explanatoryList}>
												<li>Data: {currentDate}</li>
												<li>
													Assinatura Âncora: {regionNames[0] || 'Imagem'} - ID {comparisonDetails.results[0]?.regionId || 'N/A'}
												</li>
												<li>
													Limiar de Similaridade: {"87%"}
												</li>
											</ul>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>

					{/* Coluna 2: Similaridade do Modelo e Estrutural */}
					<Grid item xs={12} md={4}>
						<TableContainer component={Paper} className={classes.tableContainer}>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell className={classes.explanatoryCell}>
											<Typography variant="subtitle1">
												<strong>Similaridade da Rede Neural e Estrutural:</strong>
											</Typography>
											<ul className={classes.explanatoryList}>
												<li>0% a 87%: DIFICILMENTE Similar</li>
												<li>88% a 97%: POSSIVELMENTE Similar</li>
												<li>98% a 100%: EXTREMAMENTE Similar</li>
											</ul>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>

					{/* Coluna 3: Diferença de Pressão da Caneta */}
					<Grid item xs={12} md={4}>
						<TableContainer component={Paper} className={classes.tableContainer}>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell className={classes.explanatoryCell}>
											<Typography variant="subtitle1">
												<strong>Diferença de Pressão da Caneta:</strong>
											</Typography>
											<ul className={classes.explanatoryList}>
												<li>0%: Pressões iguais.</li>
												<li>Próximo de 0%: Pequena diferença.</li>
												<li>Valores maiores: Diferença mais significativa.</li>
											</ul>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>

				<Box >
					<Grid className={classes.explanatoryCell}>
						<Typography variant="subtitle1">
							<strong>Sobre o percentual de comparação:</strong>
						</Typography>
						<Typography variant="body1" style={{ display: 'block', width: '100%' }}>
							O algoritimo da rede neural considera o fundo branco e a tinta da caneta na análise,
							por isso seus percentuais de similaridade sempre serão acima de 50% mesmo para pouca similaridade.
						</Typography>
					</Grid>
				</Box>

				{/* Tabela de Detalhes */}
				<TableContainer
					component={Paper}
					className={classes.tableContainer}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Comparação ID</TableCell>
								<TableCell>Lista</TableCell>
								<TableCell>Âncora</TableCell>
								<TableCell>Similaridade Rede Neural</TableCell>
								<TableCell>Similaridade Estrutural</TableCell>
								<TableCell>Diferença Pressão da Caneta</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{comparisonDetails &&
								comparisonDetails.results &&
								comparisonDetails.results.map((result: ComparisonResult, index: number) => {
									const currentChip = comparisonChips.find(
										(chip) => chip.id === result.regionId
									);
									return (
										<TableRow key={result.regionId}>
											<TableCell>
												{/* Lista Numérica Sequencial */}
												{index + 1}
											</TableCell>
											<TableCell>
												{currentChip && (
													<Grid>
														<Typography variant="body2">Imagem ID: {result.image_id}</Typography>
														{result.test_image_base64 && (
															<img
																src={`data:image/png;base64,${result.test_image_base64}`}
																alt={`Assinatura Comparada ${index + 1}`}
																style={{ maxWidth: "100px" }}
															/>
														)}
													</Grid>
												)}
											</TableCell>
											<TableCell>
												{currentChip && (
													<div>
														<Typography variant="body2">Imagem ID: {result.anchor_image_id}</Typography>
														{result.anchor_image_base64 && (
															<img
																src={`data:image/png;base64,${result.anchor_image_base64}`}
																alt={`Assinatura Âncora ${index + 1}`}
																style={{ maxWidth: "100px" }}
															/>
														)}
													</div>
												)}
											</TableCell>
											<TableCell>
												{/* Similaridade do Modelo */}
												<Typography
													variant="body1"
													className={classes.percentage}
													style={{
														color: getSimilarityColor(result.cosine_similarity * 100),
													}}>
													{(result.cosine_similarity * 100).toFixed(2)}%
												</Typography>
											</TableCell>
											<TableCell>
												{/* Similaridade Estrutural */}
												<Typography
													variant="body1"
													className={classes.percentage}
													style={{
														color: getSimilarityColor(result.ssim * 100),
													}}>
													{(result.ssim * 100).toFixed(2)}%
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1">
													{calcularDiferencaPercentual(result.pressure1, result.pressure2)}
												</Typography>
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>

				{/* Seções de Observações, Justificativa e Conclusão */}
				{report && report.length > 0 ? (
					report.map((reportItem, index) => (

						<Box mt={2} >
							<Box mt={2}>
								<Typography variant="h6">Observações:</Typography>
							</Box>
							<Typography variant="body1">
								{reportItem.Observacoes || "N/A"}
							</Typography>

							<Box mt={2}>
								<Typography variant="h6">Análise Visual:</Typography>
							</Box>
							<Typography variant="body1">
								{reportItem.AnaliseVisual || "N/A"}
							</Typography>

							<Box mt={2}>
								<Typography variant="h6">Justificativa do Resultado:</Typography>
							</Box>
							<Typography variant="body1">
								{reportItem.JustificativaDoResultado || "N/A"}
							</Typography>

							<Box mt={2}>
								<Typography variant="h6">Conclusão:</Typography>
							</Box>
							<Typography variant="body1">
								{reportItem.Conclusao || "N/A"}
							</Typography>
						</Box>
					))
				) : (
					<Typography variant="h6" color="textPrimary" component="strong">
						<div className="spacer-60"></div>
						<strong>
							** Hum, algo deu errado com a geração do Relatório. 
							Recarregue a página e tente novamente por favor! **
						</strong>
					</Typography>
				)}
			</div>

			<Box mt={2}>
				<label htmlFor="button-download">
					{" "}
					<div
						className="btn-sign"
						onClick={handleDownloadReport}
						style={{ marginTop: "30px", marginBottom: "40px" }}>
						<CloudDownloadIcon
							fontSize="small"
							className="upload-icon"
						/>
						Download Relatório
					</div>
				</label>
			</Box>
		</div>
	);
};

export default DetailedReport;