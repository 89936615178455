
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const Footer = () => (
	<footer
		className="footer-light"
		style={{
			marginTop: "0rem",
			marginBottom: "0rem",
			paddingTop: "0rem",
			paddingBottom: "0rem",
		}}>
		<div className="subfooter">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="de-flex">
							<div className="de-flex-col">
								<span className="social-icons" onClick={() => window.open("https://soilore.com/", "_blank")}>
									<span
										className="author_list_info"
										style={{ fontSize: "14px" }}>
										<span className="social-icons">&copy; Copyright - Soilore Tech</span>
									</span>
								</span>
							</div>
							<div className="de-flex-col">
								<span className="social-icons" onClick={() => window.open("https://soilore.com/termos-de-uso/", "_blank")}>
									<span
										className="author_list_info"
										style={{ fontSize: "14px" }}>
										<span className="social-icons">&copy; Termos</span>
									</span>
								</span>
							</div>
							<div className="de-flex-col">
								<span
									className="social-icons"
									onClick={() =>
										window.open("https://soilore.com/politica-de-privacidade/", "_blank")
									}>
									<span
										className="author_list_info"
										style={{ fontSize: "14px" }}>
										<span className="social-icons">&copy; Privacidade</span>
									</span>
								</span>
							</div>
							<div className="de-flex-col">
								<div className="social-icons">
									<span
										className="social-icons"
										onClick={() =>
											window.open("https://www.linkedin.com/company/veredito-ai", "_blank")
										}>
										<LinkedInIcon sx={{ color: "#47a6ff" }} />
									</span>
									<span
										className="social-icons"
										style={{ marginLeft: "5px" }}
										onClick={() => window.open("https://www.soilore.com/contato", "_blank")}>
										<EmailOutlinedIcon sx={{ color: "#00999B" }} />
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
);
export default Footer;
