import React, { useState } from "react";
import { Box, Chip, makeStyles, Theme } from "@material-ui/core";
import useSignatureDetectionStore, { Region, DetectionResult } from "./store";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import SignatureMiniature from "./SignatureMiniature";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import AlertDialog from "../AlertDialog";


const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    cursor: "pointer",
    marginBottom: "15px",
  },
  chipPrimaryMain: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  chipPrimaryLight: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  chipPrimaryDark: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },

  miniatureContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: 'column',
    justifyContent: "center",
  },

}));


export interface SignatureChipProps {
  region: Region;
  isSaved?: boolean;
  dragStartHandler: (region: Region) => void;
  onDelete: (region: Region) => void;
  showSavedIcon?: boolean;
  forceUpdate?: boolean;
  regionName?: string;
  detectionResult: DetectionResult | null;
  currentZone: 'signed' | 'noSigned' | 'anchor' | 'comparison';
}


export default function SignatureChip({
  region,
  isSaved,
  dragStartHandler,
  onDelete,
  forceUpdate,
  regionName,
  detectionResult,
  currentZone,
}: SignatureChipProps) {

  const classes = useStyles();
  const [openDiaglog, setOpenDiaglog] = useState<boolean>(false);
  const setSelectedRegion = useSignatureDetectionStore(
    (state) => state.setSelectedRegion
  );


  const zoneConfig: Record<
    string,
    {
      label: string;
      color: "default" | "primary" | "secondary";
      className?: string;
      icon: React.ReactElement;
    }
  > = {
    signed: {
      label: `Região ${region.id}: ${region.regionName || "Selecionada"}`,
      color: "secondary",
      icon: <CancelIcon />,
    },
    noSigned: {
      label: `${region.regionName ? `${region.regionName}: Salvo` : `Região ${region.id}: Salvo`}`,
      color: "primary",
      className: classes.chipPrimaryMain,
      icon: <CheckCircleIcon />,
    },
    anchor: {
      label: `${region.regionName ? `${region.regionName}: Comparada` : `Região ${region.id}: Comparada`}`,
      color: "primary",
      className: classes.chipPrimaryLight,
      icon: <DragHandleIcon />,
    },
    comparison: {
      label: `${region.regionName ? `${region.regionName}: Âncora` : `Região ${region.id}: Âncora`}`,
      color: "primary",
      className: classes.chipPrimaryDark,
      icon: <DragHandleIcon />,
    },
  };


  const { label, color, icon, className = "" } =
    zoneConfig[currentZone] || {};


  const clickHandler = () => {
    if (region.currentZone === 'signed') {
      setSelectedRegion(region);
    }
  };


  const removeRegionHandler = async () => {
    setOpenDiaglog(false);
    onDelete(region);
  };


  const getImageUrl = () => {
    if (region.image_url) {
      // Região carregada do backend: usar a URL da imagem da região
      return region.image_url;
    } else if (detectionResult && currentZone === 'signed') {
      // Região criada originalmente: usar a URL da imagem principal
      return detectionResult.image_url;
    } else {
      // Caso contrário, retornar uma string vazia para evitar erros
      return '';
    }
  };


  return (
    <Box
      component="span"
      data-testid="chip-box"
      onClick={clickHandler}
      draggable={true}
      onDragStart={() => dragStartHandler(region)}
    >
      <div className={classes.miniatureContainer}>
        {(region.currentZone === 'signed' || region.currentZone === 'noSigned') ? (
          <>
            <Chip
              key={`${region.id}-${region.currentZone}`}
              className={`${classes.chip} ${className}`}
              label={label}
              color={color}
              icon={icon}
              size="small"
              deleteIcon={<DeleteIcon data-testid="delete-region" />}
              onDelete={() => {
                if (currentZone === 'noSigned') {
                  setOpenDiaglog(true);
                } else {
                  onDelete(region);
                }
              }}
            />
            <SignatureMiniature imageUrl={getImageUrl()} box={region.box} />
          </>
        ) : (
          <>
            <SignatureMiniature imageUrl={getImageUrl()} box={region.box} />
            <Chip
              label={`Region ${region.id}: drag here`}
              icon={<DragHandleIcon />}
              deleteIcon={<DeleteIcon data-testid="delete-region" />}
              onDelete={() => onDelete(region)}
            />
          </>
        )}
      </div>
      
      {isSaved && <CheckCircleIcon />}

      {currentZone === 'noSigned' && (
        <AlertDialog
          data-testid="alert-dialog"
          isOpen={openDiaglog}
          title={"Deseja remover a assinatura?"}
          content={"Essa operação é irreversível."}
          onAgree={removeRegionHandler}
          onDisagree={() => setOpenDiaglog(false)}
          type="alert"
        />
      )}
    </Box>
  );
}