import { CreditsProvider } from './components/SignatureDetection/CreditsContext';
import ScrollToTop from "./components/Menu/ScrollToTop";
import { createGlobalStyle } from "styled-components";
import { BrowserRouter } from "react-router-dom"; 
import AuthProvider from './AuthProvider'; 
import AppRoutes from "./Routes"; 
import Layout from "./Layout";
import "./assets/animated.css";
import "./components/App.css";
import "./assets/style.scss";


const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: set;
  }
`;


function App() {
  
  return (
    <BrowserRouter>
      <GlobalStyles />
      <AuthProvider> 
      <CreditsProvider> 
        <Layout>
          <AppRoutes /> 
        </Layout>
        </CreditsProvider>
      </AuthProvider>
      <ScrollToTop />
    </BrowserRouter>
  );
}

export default App;
