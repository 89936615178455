import { createGlobalStyle } from "styled-components";
import { useAuth } from '../../AuthProvider';
import { keyframes } from "@emotion/react";
import Reveal from "react-awesome-reveal";
import { Link } from 'react-router-dom';
import Benefit from "./Benefit";
import Economy from "./Economy";
import Footer from "./Footer";
import Packs from "./Packs";


const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const inline = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  .d-inline{
    display: inline-block;
   }
`;
const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar .search #quick_search{
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white {
    border-bottom: solid 0px #000 !important;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-3{
    display: block !important;
  }
  .jumbotron {
    margin-top: 50px;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-3{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
    color: #000;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
`;


const Home = () => {
	const { isLoggedIn, login } = useAuth();


	return (
		<div>
			<>
				<GlobalStyles />
				<section className="jumbotron no-bg">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6">
								<div className="spacer-single"></div>
								<Reveal
									className="onStep"
									keyframes={fadeInUp}
									delay={0}
									duration={600}
									triggerOnce>
									<h6 className="">
										<span className="text-uppercase color">
											Compare{" "}
											<Link
												to="/Verify"
												target="_self"
												rel="noopener noreferrer"
												style={{ color: "#00999b", textDecoration: "none" }}>
												Assinaturas
											</Link>{" "}
											com IA
										</span>
									</h6>
								</Reveal>
								<div className="spacer-10"></div>
								<Reveal
									className="onStep"
									keyframes={fadeInUp}
									delay={300}
									duration={600}
									triggerOnce>
									<h1>
										Verifique a autenticidade de assinaturas <p></p>
									</h1>
								</Reveal>
								<Reveal
									className="onStep"
									keyframes={fadeInUp}
									delay={600}
									duration={600}
									triggerOnce>
									<p className="text-left">
										Acesse e compare a similaridade entre assinaturas com inteligência artificial!
									</p>
								</Reveal>
								<div className="spacer-10"></div>
								<Reveal
									className="onStep"
									keyframes={fadeInUp}
									delay={800}
									duration={900}
									triggerOnce>
									{isLoggedIn ? (
										<Link
											to="/Verify"
											target="_self"
											rel="noopener noreferrer"
											style={{ textDecoration: "none" }}>
											<button className="btn-main">Acesso Grátis</button>
										</Link>
									) : (
										<Link
											onClick={login}
											target="_self"
											rel="noopener noreferrer"
											style={{ textDecoration: "none" }}>
											<button className="btn-main">Acesso Grátis</button>
										</Link>
									)}
									<div className="mb-sm-30"></div>
								</Reveal>
								<Reveal
									className="onStep d-inline"
									keyframes={inline}
									delay={900}
									duration={1200}
									triggerOnce>
									<div className="spacer-single"></div>
									<div className="row">
										<h2 className="style-2">Algoritimo com Ampla Base de Dados</h2>
										<div className="spacer-single"></div>

										<div className="row">
											<div className="col-lg-4 col-md-6 col-sm-4 mb30">
												<div className="de_count text-left">
													<h3>
														<span>42 </span>Milhões
													</h3>
													<h5 className="id-color">Páginas de Documentos</h5>
												</div>
											</div>

											<div className="col-lg-4 col-md-6 col-sm-4 mb30">
												<div className="de_count text-left">
													<h3>
														<span>11 </span>Milhões
													</h3>
													<h5 className="id-color">Parâmetros de Treinamento</h5>
												</div>
											</div>

											<div className="col-lg-4 col-md-6 col-sm-4 mb30">
												<div className="de_count text-left">
													<h3>
														<span>2 </span>Mil
													</h3>
													<h5 className="id-color"> Assinaturas Reais</h5>
												</div>
											</div>

											<div className="col-lg-4 col-md-6 col-sm-4 mb30">
												<div className="de_count text-left">
													<h3>
														<span>+95% </span> ✓
													</h3>
													<h5 className="id-color">Precisão</h5>
												</div>
											</div>
										</div>
									</div>
								</Reveal>
								<div className="container no-top"></div>
							</div>
							<div className="col-md-6 pl-md-5 text-center">
								<div
									className="box-url"
									style={{
										width: "100%",
										height: "auto",
										paddingTop: "0.3rem",
										paddingBottom: "0rem",
										marginLeft: "2rem",
									}}>
									<video
										src="./img/video-home.mp4"
										autoPlay
										loop
										muted
										playsInline
										style={{ width: "98.5%", height: "99.5%" }}
									/>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="container no-top">
					<div
						className="row"
						style={{ marginTop: "3rem", marginBottom: "-2rem" }}>
						<div className="col-lg-12 text-center">
							<Reveal
								className="onStep"
								keyframes={fadeInUp}
								delay={300}
								duration={600}
								triggerOnce>
								<h2 className="h2 big">Rapidez e Confiabilidade</h2>
								<p className="lead">
									Acabe com a Dúvida: Assinatura Autêntica ou Falsificada? </p>
							</Reveal>
						</div>
					</div>
				</section>
				<section className="container no-top">
					<Benefit />
				</section>
				<section className="container no-top">
					<div
						className="row"
						style={{ marginTop: "-1rem", marginBottom: "-5rem" }}>
						<div className="col-lg-12 text-center">
							<h2 className="h2 big">Investimentos Precisos</h2>
							<p className="lead">
								Calcule a Probabilidade Antes de Decidir </p>
						</div>
					</div>
					<Economy />
				</section>

				<section className="container no-top">
					<div
						className="row"
						style={{ marginTop: "-5rem", marginBottom: "-12rem" }}>
						<div className="col-lg-12 text-center"
							style={{ marginTop: "3rem", marginBottom: "0rem" }}>
							<h2 className="h2 big">Ideal para Diversos Setores</h2>
							<p className="lead">
								Integre no fluxo do seu Negócio </p>
						</div>
					</div>
				</section>

				<section>
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6">
								<img
									src="./img/sign-minimal.jpg"
									className="img-fluid img-rounded mb-sm-30"
									style={{
										marginTop: "1rem",
										marginLeft: "-2rem",
										padding: "4rem",
									}}
									alt=""
								/>
							</div>
							<div className="col-lg-6">
								<div className="spacer-single"></div>
								<Reveal
									className="onStep"
									keyframes={fadeInUp}
									delay={0}
									duration={600}
									triggerOnce>
									<h5 className="">
										<span className="text-uppercase color">Solução versátil... </span>
									</h5>
								</Reveal>
								<div className="spacer-10"></div>
								<Reveal
									className="onStep"
									keyframes={fadeInUp}
									delay={300}
									duration={600}
									triggerOnce>
									<h3 className="">Advogados, Cartórios, Empresas</h3>
								</Reveal>
								<Reveal
									className="onStep"
									keyframes={fadeInUp}
									delay={600}
									duration={600}
									triggerOnce>
									<p className="">
										Indicada para cartórios, advogados, empresas antifraude e qualquer pessoa que busque confirmar a autenticidade de uma assinatura.
										O Veredito Ink oferece uma camada adicional de segurança e confiabilidade para negócios com assinaturas manuscritas.
									</p>
									<div className="spacer-20"></div>
									<h5 className="">
										<span className="text-uppercase color">Aplicações: </span>
									</h5>
									<div className="spacer-20"></div>
									<div className="de_tab_content">
										<div className="tab-1 onStep fadeIn">
											<div className="p_list">
												<div className="p_list_pp">
													<span>
														<img
															className="lazy"
															src="./img/balance.png"
															alt=""
														/>
													</span>
												</div>
												<div
													className="p_list_info"
													style={{ marginLeft: "-0.6rem" }}>
													<h5><b>Advogados:</b></h5>
													<span>
														<b></b> <p>Valide documentos para tomadas de decisões estratégicas em processos jurídicos.</p>
													</span>
												</div>
											</div>
											<div className="p_list">
												<div className="p_list_pp">
													<span>
														<img
															className="lazy"
															src="./img/awards.png"
															alt=""
														/>
													</span>
												</div>
												<div
													className="p_list_info"
													style={{ marginLeft: "-0.6rem" }}>
													<h5><b>Cartórios:</b></h5>
													<span>
														<b></b> <p>Agilize a conferência de documentos incluindo uma camada adicional de verificação.</p>
													</span>
												</div>
											</div>
											<div className="p_list">
												<div className="p_list_pp">
													<span>
														<img
															className="lazy"
															src="./img/wallet.png"
															alt=""
														/>
													</span>
												</div>
												<div
													className="p_list_info"
													style={{ marginLeft: "-0.6rem" }}>
													<h5><b>Empresas:</b></h5>
													<span>
														<b></b> <p> Identifique assinaturas suspeitas com antecedência, aumentando a segurança das operações.</p>
													</span>
												</div>
											</div>
										</div>
									</div>
								</Reveal>
								<div className="spacer-10"></div>
								<Reveal
									className="onStep"
									keyframes={fadeInUp}
									delay={800}
									duration={900}
									triggerOnce>
									{isLoggedIn ? (
										<Link
											to="/Verify"
											target="_self"
											rel="noopener noreferrer"
											style={{ textDecoration: "none" }}>
											<button className="btn-main">Acessar</button>
										</Link>
									) : (
										<Link
											onClick={login}
											target="_self"
											rel="noopener noreferrer"
											style={{ textDecoration: "none" }}>
											<button className="btn-main">Acessar</button>
										</Link>
									)}
									<div className="mb-sm-30"></div>
								</Reveal>
							</div>
						</div>
					</div>
				</section>

				<section className="container no-top">
					<Reveal
						className="onStep"
						keyframes={fadeInUp}
						delay={600}
						duration={600}
						triggerOnce>
						<div className="spacer-double"></div>
						<div className="row">
							<div className="col-lg-12 text-center">
								<h2 className="style-2">PERSONALIZE E INTEGRE</h2>
								<p className="lead">
									Podemos adaptar a tecnologia ao seu negócio, contate-nos.
								</p>
								<div className="d-flex justify-content-center align-items-center">
									<a
										href="https://soilore.com/contato"
										target="_blank"
										rel="noopener noreferrer"
										className="btn-logout"
									>
										Contato
									</a>
								</div>
							</div>
							<div className="spacer-double"></div>
							<div className="spacer-double"></div>
							<div className="col-lg-12 text-center">
								<h2 className="style-2">PAGUE PELO QUE USAR</h2>
								<p className="lead">
									Sem mensalidades, carregue e use.
								</p>
							</div>
							<div className="col-lg-12">
								<Packs />
							</div>
						</div>
					</Reveal>
				</section>

				<section className="container no-top">
					<div className="row">
						<div className="col-lg-12 text-center">
							<h2 className="style-2">VEJA COMO USAR</h2>
							<div className="small-border"></div>
						</div>
					</div>
					<div className="container px-0">
						<div className="row">
							<div className="col-lg-2 col-sm-4 col-6 mb30">
								<span className="box-url">
									<h2>➟</h2>
									<h5>Carregar Documento</h5>
								</span>
							</div>

							<div className="col-lg-2 col-sm-4 col-6 mb30">
								<span className="box-url">
									<h2>➟</h2>
									<h5>Selecionar Assinaturas</h5>
								</span>
							</div>

							<div className="col-lg-2 col-sm-4 col-6 mb30">
								<span className="box-url">
									<h2>➟</h2>
									<h5>Iniciar Comparação</h5>
								</span>
							</div>

							<div className="col-lg-2 col-sm-4 col-6 mb30">
								<span className="box-url">
									<h2>➟</h2>
									<h5>Verificar Similaridade</h5>
								</span>
							</div>

							<div className="col-lg-2 col-sm-4 col-6 mb30">
								<span className="box-url">
									<h2>➟</h2>
									<h5>Baixar Laudo</h5>
								</span>
							</div>

							<div className="col-lg-2 col-sm-4 col-6 mb30">

								{isLoggedIn ? (
									<Link className="social-icons"
										to="/Verify"
										style={{
											textDecoration: "none",
											display: "block"
										}}
									>
										<span className="box-url">
											<i className="social-icons"></i>
											<h2>🔘</h2>
											<h5>Começar</h5>
										</span>
									</Link>
								) : (
									<Link className="social-icons"
										onClick={login}
										style={{
											textDecoration: "none",
											display: "block"
										}}
									>
										<span className="box-url">
											<i className="social-icons"></i>
											<h2>🔘</h2>
											<h5>Começar</h5>
										</span>
									</Link>
								)}
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</>
		</div>
	);
};
export default Home;
