import {
	Box,
	CircularProgress,
	Container,
	Divider,
	Grid,
	makeStyles,
	Snackbar,
	Theme,
	Tooltip,
	Typography,
} from "@material-ui/core";
import React, { useState, useEffect, useRef, forwardRef } from "react";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import useSignatureDetectionStore from "./store";
import DetectionImageCard from "./DetectionImageCard";
import DetectionResultCard from "./DetectionResultCard";
import DetailedReport from "./DetailedReport";
import { useAuth } from "../../AuthProvider";

// Estilos do componente
const useStyles = makeStyles((theme: Theme) => ({
	muiGrid: {
		minHeight: CARD_MIN_HEIGHT,
		minWidth: 1350,
	},
	muiGrid2: {
		minHeight: CARD_MIN_HEIGHT,
		minWidth: 1280,
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "auto",
		background: "#f4f0e8",
		padding: theme.spacing(2),
		border: "dashed 1px #888",
		marginTop: "80px",
	},
	textTop: {
		minHeight: 100,
		position: "relative",
		textAlign: "center",
		padding: "0px 0",
		marginBottom: "20px",
		color: "#a2a2a2",
		background: "#FDFBF8",
		borderRadius: 0,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},

	divider: {
		color: "#888",
		border: "dashed 1px #888",
	},
}));

// Altura mínima do card
export const CARD_MIN_HEIGHT = 800;

// Componente principal
export default function SignatureLoader() {
	const classes = useStyles();
	// Verifica se o usuário está logado
	const { isLoggedIn } = useAuth();
	// Mensagem de erro
	const [errorMessage, setErrorMessage] = useState<string>("");
	// Estado de upload
	const [isUploading, setIsUploading] = useState(false);

	// Funções do store
	const setImageFile = useSignatureDetectionStore((state) => state.setImageFile);
	const { comparisonDetails, comparisonResult, comparisonChips } = useSignatureDetectionStore();
	const setDetectionResult = useSignatureDetectionStore((state) => state.setDetectionResult);
	const report = useSignatureDetectionStore((state) => state.report);
	const setReport = useSignatureDetectionStore((state) => state.setReport);
	const userId = useSignatureDetectionStore((state) => state.user?.user_id);
	// Estado para exibir o relatório
	const [showReport, setShowReport] = useState(false);
	const clearSignedRegions = useSignatureDetectionStore((state) => state.clearSignedRegions);
	// Referência para o relatório detalhado
	const detailedReportRef = useRef<HTMLDivElement>(null);

	// Cria as pastas do usuário no servidor
	const createFolders = async () => {
		try {
			await axios.post(`${process.env.REACT_APP_API_URL}create-folders/`, {
				userId: userId,
			}, {
				headers: { "Content-Type": "multipart/form-data" },
			});
		} catch (err) {
			console.error("Erro ao criar pastas:", err);
		}
	};

	// ... (código do frontend) ...

// Faz o upload do arquivo para o servidor
const uploadFile = async (file: File) => {
    try {
        setErrorMessage("");
        setIsUploading(true);
        await createFolders();

        const data = new FormData();
        data.append("file", file, file.name);

        if (userId !== undefined) {
            data.append("userId", userId.toString());
        }

        // Busca os resultados da detecção (chama diretamente a API /api/)
        const getDetectionResults = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}api/` as string, data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                setDetectionResult(response.data);
                setReport(response.data.report ? response.data.report : []);
                setIsUploading(false);
            } catch (err: any) {
                // Lida com erros
                if (err.response?.status === 404) {
                    setTimeout(getDetectionResults, 1000);
                } else {
                    console.error("Erro na chamada da API:", err);
                    setErrorMessage("Erro ao processar a imagem.");
                    setIsUploading(false);
                }
            }
        };
        getDetectionResults();
    } catch (err: any) {
        console.error("Erro geral no upload:", err);
        setErrorMessage("Erro ao enviar a imagem.");
        setIsUploading(false);
    }
};

	// Lida com a mudança no input de arquivo
	const inputChangeHandler = (event: any) => {
		let file: any;
		if (event?.target?.files && event.target.files.length) {
			file = event.target.files[0];
		} else {
			return;
		}
		// Valida o tipo de arquivo
		if (file.type !== "image/png" && file.type !== "image/jpeg") {
			setErrorMessage("Sua imagem deve estar no formato JPEG ou PNG.");
			return;
		}
		clearSignedRegions();
		setImageFile(file);
		uploadFile(file);
	};

	// Componente Box com referência
	const BoxWithRef = forwardRef((props: any, ref: any) => <Box {...props} ref={ref} />);

	// Efeitos colaterais
	useEffect(() => { }, [comparisonChips, comparisonDetails, comparisonResult, report]);
	useEffect(() => { }, [isLoggedIn]);
	useEffect(() => {
		if (showReport && detailedReportRef.current) {
			detailedReportRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [showReport]);

	// Retorno do componente (JSX)
	return (
		<Container className={classes.muiGrid}>

			<Box component="div" mt={3} />

			<Box component="div" mt={3}>
				<Grid container direction="row" justify="space-between" alignItems="center"></Grid>
			</Box>

			{/* Grid para o card de imagem e o card de resultados */}
			<Box component="div" mt={3}>
				<Grid container direction="row" spacing={5}>
					{/* Card de imagem */}
					<Grid item sm={12} lg={6}>
						{/* Seção para o título e o botão de upload */}
						<Box className={classes.textTop} component="div">
							<Grid item sm={12} lg={8}>
								<Typography variant="overline" color="textPrimary" component="strong">
									Carregue a imagem do Documento
								</Typography>
							</Grid>
							<Grid item sm={12} lg={8}>
								{/* Tooltip para exibir uma mensagem ao passar o mouse */}
								<Tooltip title={isLoggedIn ? "Selecione a imagem com as assinaturas." : "Clique em 'Entrar' para carregar um documento"}>
									<div>
										{/* Input de arquivo oculto */}
										<input
											id="button-file"
											data-testid="button-file"
											type="file"
											accept="image/*"
											onChange={inputChangeHandler}
											hidden
											disabled={!isLoggedIn}
										/>
										{/* Label para o input de arquivo */}
										<label htmlFor="button-file">
											<div className={`btn-sign ${!isLoggedIn ? "disabled" : ""}`}>
												{/* Ícone de upload */}
												<CloudUploadIcon fontSize="small" className="upload-icon" />

												{/* Exibe um indicador de progresso durante o upload */}
												{isUploading ? (
													<CircularProgress size={24} color="inherit" />
												) : (
													"Carregar Documento"
												)}
											</div>
										</label>
									</div>
								</Tooltip>
							</Grid>
						</Box>

						{/* Componente para exibir a imagem */}
						<DetectionImageCard />

						<Box component="div" mt={3} />
					</Grid>

					{/* Card de resultados */}
					<Grid item sm={12} lg={6}>
						<DetectionResultCard setShowReport={setShowReport} />
					</Grid>
				</Grid>
			</Box>

			<Divider className={classes.divider} />

			{/* Relatório detalhado */}
			{showReport && comparisonResult !== null && comparisonDetails && (
				<BoxWithRef className={classes.muiGrid2} component="div" mt={3} ref={detailedReportRef}>
					<Grid container spacing={5}>
						<Grid item xs={12}>
							<DetailedReport
								comparisonResult={comparisonResult}
								comparisonDetails={comparisonDetails}
								comparisonChips={comparisonChips}
								regionNames={comparisonChips.map((chip) => chip.regionName || "")}
								report={report}
								id="detailed-report"
							/>
						</Grid>
					</Grid>

					<Box component="div" mt={1} mb={1}>
						<Divider className={classes.divider} />
					</Box>
				</BoxWithRef>
			)}

			{/* Snackbar para exibir mensagens de erro */}
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				open={errorMessage !== ""}
				autoHideDuration={3000}
				onClose={() => setErrorMessage("")}
			>
				<Alert severity="error" onClose={() => setErrorMessage("")}>
					{errorMessage}
				</Alert>
			</Snackbar>
		</Container>
	);
}