import {
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import useSignatureDetectionStore, { Region } from "./store";
import saveAs from "file-saver";


export default function DetectionDownload() {
  const loadedSignatures = useSignatureDetectionStore((state) => state.loadedSignatures);
  const detectionResult = useSignatureDetectionStore((state) => state.detectionResult);

  const isImageSigned = detectionResult
    ? detectionResult.regions
      .some((region: Region) => region.currentZone === 'signed')
    : false;


  const generateSignatureImage = async (imageUrl: string, fileName: string) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      saveAs(blob, `${fileName}.png`);
    } catch (error) {
      console.error("Erro ao baixar a imagem:", error);
    }
  };


  const handleDownload = async () => {
    // Filtra as assinaturas carregadas que estão na zona 'noSigned'
    const noSignedSignatures = loadedSignatures.filter(
      (signature) => signature.currentZone === 'noSigned'
    );

    // Validação - você pode adicionar mais regras, se necessário
    if (noSignedSignatures.length === 0) {
      alert('Não há assinaturas carregadas para baixar.');
      return;
    }

    // Baixa as imagens das assinaturas filtradas
    await Promise.all(
      noSignedSignatures.map(async (signature, index) => {
        const fileName = `assinatura-${index + 1}`;
        await generateSignatureImage(signature.image_url!, fileName);
      })
    );
  };


  return (
    <Box component="div" mt={1}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography
            variant="overline"
            color="textPrimary"
            component="strong">
            Alguma assinatura selecionada?
            <Box component="span" ml={2}>
              <Typography
                variant="h6"
                component="strong"
                color={isImageSigned ? "primary" : "secondary"}
              >
                {isImageSigned ? "SIM" : "NÃO"}
              </Typography>
            </Box>
          </Typography>
        </Grid>
        <Grid item>
          <label htmlFor="button-download">
            <div
              className={`btn-sign ${loadedSignatures.length === 0
                  ? "disabled"
                  : ""
                }`}
              onClick={handleDownload}
            >
              <CloudDownloadIcon fontSize="small" className="upload-icon" />
              Download
            </div>
          </label>
        </Grid>
      </Grid>
    </Box>
  );
}