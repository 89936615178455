import InfoDialog from "../InfoDialog";
import React, { useState } from "react";
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../AuthProvider';
import { useCredits } from './CreditsContext';


const Packs = function () {
	const { credits, handleCheckout } = useCredits();
	const { isLoggedIn, login, logout } = useAuth();
	const [openAlert, setOpenAlert] = useState(false);
	const [alertData, setAlertData] = useState<{ title: string; content: string; type: "info" | "alert" | "error" } | null>(null);

	const handleOpenAlert = (data: { title: string; content: string; type: "info" | "alert" | "error" }) => {
		setAlertData(data);
		setOpenAlert(true);
	};

	const handleCloseAlert = () => {
		setOpenAlert(false);
		setAlertData(null);
	};

	const handleCheckoutClick = (pack: string) => {
		if (isLoggedIn) {
			handleCheckout(pack);
		} else {
			handleOpenAlert({
				title: "Autenticação Necessária!",
				content: "Você precisa estar logado para comprar créditos.",
				type: "info"
			});
		}
	};


	return (
		<div className="col-md"
			style={{ marginLeft: "0rem", marginTop: "4rem", fontSize: "16px" }}>
			<div>
				<ol className="author_list">
					<li>
						<div className="author_list_pp">
							{isLoggedIn ? (
								<span>
									<NavLink
										to="/Verify"
										style={{ textDecoration: "none" }}>
										<img
											className="lazy"
											src="./img/pack-1.png"
											alt=""
										/>
									</NavLink>
								</span>
							) : (
								<span>
									<NavLink
										to="#"
										onClick={login}
										style={{ textDecoration: "none" }}>
										<img
											className="lazy"
											src="./img/pack-1.png"
											alt=""
										/>
									</NavLink>
								</span>
							)}
						</div>
						<div className="author_list_info">
							<h5>Pack Livre ➟ Grátis</h5>
							<span className="bot"></span> 1 Consulta/Dia
						</div>
					</li>

					<li>
						<div className="author_list_pp">
							<span>
								<NavLink
									to="#"
									onClick={() => handleCheckoutClick("Start")}
									style={{ textDecoration: "none" }}>
									<img
										className="lazy"
										src="./img/pack-15.png"
										alt=""
									/>
								</NavLink>
							</span>
						</div>
						<div className="author_list_info">
							<h5>Pack 15 ➟ R$49,00</h5>
							<span className="bot"></span>15 Créditos
						</div>
					</li>

					<li>
						<div className="author_list_pp">
							<span>
								<NavLink
									to="#"
									onClick={() => handleCheckoutClick("Pro")}
									style={{ textDecoration: "none" }}>
									<img
										className="lazy"
										src="./img/pack-40.png"
										alt=""
									/>
								</NavLink>
							</span>
						</div>
						<div className="author_list_info">
							<h5>Pack 40 ➟ R$89,00</h5>
							<span className="bot"></span>40 Créditos
						</div>
					</li>

					<li>
						<div className="author_list_pp">
							<span>
								<NavLink
									to="#"
									onClick={() => handleCheckoutClick("Ultimate")}
									style={{ textDecoration: "none" }}>
									<img
										className="lazy"
										src="./img/pack-100.png"
										alt=""
									/>
								</NavLink>
							</span>
						</div>
						<div className="author_list_info">
							<h5>Pack 100 ➟ R$189,00</h5>
							<span className="bot"></span>100 Créditos
						</div>
					</li>
				</ol>

				<InfoDialog
					data-testid="info-dialog"
					isOpen={openAlert}
					title={alertData?.title || ""}
					content={alertData?.content || ""}
					type={alertData?.type || "info"}
					onCancel={handleCloseAlert}
				/>
			</div>
		</div>
	)
};
export default Packs;