import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
} from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) => ({
  popup: {
    background: '#F7EEDD',
    fontFamily: 'Lato, sans-serif',
    fontSize: '16px',
    padding: theme.spacing(1),
    border: "solid 2px #000",
    borderRadius: "5px",
    boxShadow: "6px 6px 0px 0px rgba(0,0,0,0.09)",
    transition: ".3s",
    "&:hover": {
      transition: ".3s",
    },
    "&.left": {
      textAlign: "center",
    },
    "&.default": {
      background: "#F7EEDD",
    },
    // Estilos específicos para cada tipo de alerta
    "&.info": {
      background: "#e7f3fe",
    },
    "&.alert": {
      background: "#e0f8ff",
    },
    "&.error": {
      background: "ffe3e2",
    },
  },
  title: {
    fontFamily: 'Georgia, serif',
    fontSize: '20px',
  },
  contentText: {
    fontFamily: 'Verdana, sans-serif',
    fontSize: '14px',
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  actions: {
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    fontSize: '14px'
  },
}));


interface InfoDialogProps {
  isOpen: boolean;
  title: string;
  content: string;
  onCancel: () => void;
  type: "default" | "info" | "alert" | "error";
}


export default function InfoDialog({
  isOpen,
  title,
  content,
  onCancel,
  type,
}: InfoDialogProps) {

  const classes = useStyles();

  
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="info-dialog-title"
      aria-describedby="info-dialog-description"
      classes={{ paper: `${classes.popup} ${type}` }}
    >
      <DialogTitle className={classes.title} id="info-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText} id="info-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={onCancel} style={{ color: "#4c4c4c" }} autoFocus>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
