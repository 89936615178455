import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
  TextField,
} from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) => ({
  popup: {
    background: '#F7EEDD',
    fontFamily: 'Lato, sans-serif',
    fontSize: '16px',
    padding: theme.spacing(1),
    border: "solid 2px #000",
    borderRadius: "5px",
    boxShadow: "6px 6px 0px 0px rgba(0,0,0,0.09)",
    transition: ".3s",
    "&:hover": {
      transition: ".3s",
    },
    "&.left": {
      textAlign: "center",
    },
  },
  title: {
    fontFamily: 'Georgia, serif',
    fontSize: '20px',
  },
  contentText: {
    fontFamily: 'Verdana, sans-serif',
    fontSize: '14px',
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  actions: {
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    fontSize: '14px'
  },
}));


interface SaveDialogProps {
  isOpen: boolean;
  onSave: (fileName: string) => void;
  onCancel: () => void;
}


export default function SaveDialog({ isOpen, onSave, onCancel }: SaveDialogProps) {
  const classes = useStyles();
  const [fileName, setFileName] = React.useState('');

  const handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(event.target.value);
  };

  const handleSaveClick = () => {
    onSave(fileName);
  };

  
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="save-dialog-title"
      classes={{ paper: classes.popup }}
    >
      <DialogTitle id="save-dialog-title" className={classes.title}>Salvar Assinatura?</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText}>Digite um nome para a imagem:</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="fileName"
          label="Nome do Arquivo"
          type="text"
          fullWidth
          value={fileName}
          onChange={handleFileNameChange}
          className={classes.textField}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={onCancel} color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleSaveClick} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
