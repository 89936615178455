import React, { useEffect, useState } from "react";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { NavLink } from 'react-router-dom';
import useOnclickOutside from "react-cool-onclickoutside";
import { useAuth } from '../../AuthProvider';
import { useCredits } from '../SignatureDetection/CreditsContext';
import Tooltip from '@mui/material/Tooltip';

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

const Header = function () {
	const [showmenu, btn_icon] = useState(false);

	const [openMenu, setOpenMenu] = React.useState(false);
	const [openMenu1, setOpenMenu1] = React.useState(false);
	const [openMenu2, setOpenMenu2] = React.useState(false);
	const [openMenu3, setOpenMenu3] = React.useState(false);
	const handleBtnClick = () => {
		setOpenMenu(!openMenu);
	};
	const handleBtnClick1 = () => {
		setOpenMenu1(!openMenu1);
	};
	const handleBtnClick2 = () => {
		setOpenMenu2(!openMenu2);
	};
	const handleBtnClick3 = () => {
		setOpenMenu3(!openMenu3);
	};
	const closeMenu = () => {
		setOpenMenu(false);
	};
	const closeMenu1 = () => {
		setOpenMenu1(false);
	};
	const closeMenu2 = () => {
		setOpenMenu2(false);
	};
	const closeMenu3 = () => {
		setOpenMenu3(false);
	};
	const ref = useOnclickOutside(() => {
		closeMenu();
	});
	const ref1 = useOnclickOutside(() => {
		closeMenu1();
	});
	const ref2 = useOnclickOutside(() => {
		closeMenu2();
	});
	const ref3 = useOnclickOutside(() => {
		closeMenu3();
	});

	useEffect(() => {
		const header = document.getElementById("myHeader");
		const totop = document.getElementById("scroll-to-top");

		if (!header) {
			return;
		}
		const sticky = header.offsetTop;
		const scrollCallBack = window.addEventListener("scroll", () => {
			btn_icon(false);
			if (window.pageYOffset > sticky) {
				header.classList.add("sticky");
				totop.classList.add("show");
			} else {
				header.classList.remove("sticky");
				totop.classList.remove("show");
			}
			if (window.pageYOffset > sticky) {
				closeMenu();
			}
		});
		return () => {
			window.removeEventListener("scroll", scrollCallBack);
		};
	}, []);

	const { isLoggedIn, login, logout } = useAuth();
	const { credits, handleCheckout } = useCredits();

	return (
		<header id="myHeader" className="navbar white">
			<div className="container">
				<div className="row w-100-nav">
					<div className="logo px-1">
						<div className="navbar-title navbar-item">
							<NavLink to="/">
								<img
									src="./img/logo-veredito-ink-1000.png"
									className="img-fluid d-block"
									alt="#"
								/>
								<img
									src="./img/logo-veredito-ink-1000.png"
									className="img-fluid d-3"
									alt="#"
								/>
								<img
									src="./img/logo-veredito-ink-1000.png"
									className="img-fluid d-none"
									alt="#"
								/>
							</NavLink>
						</div>
					</div>

					<BreakpointProvider>
						<Breakpoint l down>
							{showmenu && (
								<div className="menu">
									<div className="navbar-item" >
										<NavLink to="/Verify" onClick={() => btn_icon(!showmenu)}>
											Verificar
										</NavLink>
									</div>
									<div className="navbar-item">
										<NavLink to="/Dashboard" style={{ fontSize: "1.15rem" }}>
											Painel
											<span className="lines"></span>
										</NavLink>
									</div>
									<div className="navbar-item">
										<div ref={ref1}>
											<div
												className="dropdown-custom dropdown-toggle btn"
												onClick={handleBtnClick1}>
												Créditos
											</div>
											{openMenu1 && (
												<div className="item-dropdown">
													<div
														className="dropdown"
														onClick={closeMenu1}>
														<NavLink
															to="/Dashboard"
															onClick={() => btn_icon(!showmenu)}>
															Pack 1 Consulta - Grátis
														</NavLink>
														<NavLink
															to="/Dashboard"
															onClick={() => btn_icon(!showmenu)}>
															Pack 15 Consultas - R$49,00
														</NavLink>
														<NavLink
															to="/Dashboard"
															onClick={() => btn_icon(!showmenu)}>
															Pack 40 Consultas - R$89,00
														</NavLink>
														<NavLink
															to="/Dashboard"
															onClick={() => btn_icon(!showmenu)}>
															Pack 100 Consultas - R$189,00
														</NavLink>
													</div>
												</div>
											)}
										</div>
									</div>

								</div>
							)}
						</Breakpoint>

						<Breakpoint xl>
							<div className="menu">
								<div className="navbar-item">
									<NavLink to="/Verify" style={{ fontSize: "1.15rem" }}>
										Verificar
										<span className="lines"></span>
									</NavLink>
								</div>
								<div className="navbar-item">
									<NavLink to="/Dashboard" style={{ fontSize: "1.15rem" }}>
										Painel
										<span className="lines"></span>
									</NavLink>
								</div>
								<div className="navbar-item">
									<div ref={ref1}>
										<div
											className="dropdown-custom dropdown-toggle btn"
											onMouseEnter={handleBtnClick1}
											onMouseLeave={closeMenu1}
											style={{ fontSize: "1.15rem" }}>
											Créditos
											<span className="lines"></span>
											{openMenu1 && (
												<div className="item-dropdown">
													{isLoggedIn ? (
														<div
															className="dropdown"
															onClick={closeMenu1}>
															<NavLink to="/Verify" >
																Pack 1 Consulta ➟ Grátis
															</NavLink>
															<NavLink onClick={() => handleCheckout("Start")}>
																Pack 15 Consultas ➟ R$49,00
															</NavLink>
															<NavLink onClick={() => handleCheckout("Pro")}>
																Pack 40 Consultas ➟ R$89,00
															</NavLink>
															<NavLink onClick={() => handleCheckout("Ultimate")}>
																Pack 100 Consultas ➟ $189,00
															</NavLink>
														</div>
													) : (
														<div
															className="dropdown"
															onClick={closeMenu1}>
															<NavLink onClick={login}>
																Pack 1 Consulta ➟ Grátis
															</NavLink>
															<Tooltip title="Clique em 'Entrar' primeiro.">
																<NavLink onClick={() => handleCheckout("Start")}>
																	Pack 15 Consultas ➟ R$49,00
																</NavLink>
																<NavLink onClick={() => handleCheckout("Pro")}>
																	Pack 40 Consultas ➟ R$89,00
																</NavLink>
																<NavLink onClick={() => handleCheckout("Ultimate")}>
																	Pack 100 Consultas ➟ $189,00
																</NavLink>
															</Tooltip>
														</div>

													)}
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</Breakpoint>
					</BreakpointProvider>

					<div className="mainside">
						{isLoggedIn ? (
							<button className="btn-logout" onClick={logout}>
								Sair
							</button>
						) : (
							<button className="btn-main" onClick={login}>
								Entrar
							</button>
						)}
					</div>
				</div>

				<button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
					<div className="menu-line white"></div>
					<div className="menu-line1 white"></div>
					<div className="menu-line2 white"></div>
				</button>
			</div>
		</header>
	);
};

export default Header;
