import React, { useState, useEffect, useCallback, useContext, createContext } from "react";
import useSignatureDetectionStore from "./store";
import { loadStripe } from "@stripe/stripe-js";
import { useAuth } from "../../AuthProvider";


interface CreditsContextType {
	credits: number;
	handleCheckout: (planName: string) => Promise<void>;
}

const CreditsContext = createContext<CreditsContextType | null>(null);

export const CreditsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { isLoggedIn } = useAuth();
	const credits = useSignatureDetectionStore((state) => state.credits);
	const setCredits = useSignatureDetectionStore((state) => state.setCredits);
	const [updateCredits, setUpdateCredits] = useState<boolean>(false);
	const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_PUBLIC_API_KEY;
	const API_URL = process.env.REACT_APP_API_URL || "https://vereditoink.soilore.com/api/";
	
	//console.log("STRIPE API KEY: ", STRIPE_API_KEY);

	const getJwtToken = () => {
		const cookieValue = document.cookie
			.split("; ")
			.find((row) => row.startsWith("jwt_token="))
			?.split("=")[1];
		return cookieValue;
	};


	const fetchCredits = useCallback(async () => {
		//console.log("Iniciando fetchCredits...");
		if (isLoggedIn) {
			try {
				const jwt_token = getJwtToken();
				const response = await fetch(`${API_URL}stripe/user/credits`, {
					credentials: "include",
					headers: {
						Authorization: `Bearer ${jwt_token}`,
					},
				});
				if (response.ok) {
					const data = await response.json();
					setCredits(data.credits);
					setUpdateCredits(false);
				} else {
					console.error("Erro ao buscar créditos:", await response.text());
				}
			} catch (error) {
				console.error("Erro no fetchCredits:", error);
			}
		}
	}, [isLoggedIn, API_URL, setCredits]);


	const handleCheckout = async (planName: string) => {
		try {
			const jwt_token = getJwtToken();
			if (jwt_token) {
				try {
					const response = await fetch(`${API_URL}stripe/create-checkout-session/${planName}`, {
						method: "POST",
						credentials: "include",
						headers: {
							Authorization: `Bearer ${jwt_token}`,
						},
					});
					if (response.ok) {
						const data = await response.json();
						try {
							const stripe = await loadStripe(STRIPE_API_KEY!);
							console.log("STRIPE API KEY: ", STRIPE_API_KEY);
							if (stripe) {
								const result = await stripe.redirectToCheckout({ sessionId: data.sessionId });
								if (result.error) {
									console.error("Erro no checkout do Stripe:", result.error);
									setUpdateCredits(true);
								}
							} else {
								console.error("Falha ao carregar o Stripe");
							}
						} catch (error) {
							console.error("Erro no bloco try interno do handleCheckout:", error);
						}
					} else {
						console.error("Erro ao iniciar o checkout:", await response.text());
					}
				} catch (error) {
					console.error("Erro no bloco try interno do handleCheckout:", error);
				}
			} else {
				console.error("Token JWT não encontrado. O usuário está logado?");
			}
		} catch (error) {
			console.error("Erro geral no handleCheckout:", error);
		}
	};


	useEffect(() => {
		if (isLoggedIn) {
			fetchCredits();
		}
		const urlParams = new URLSearchParams(window.location.search);
		const sessionId = urlParams.get("session_id");
		if (sessionId) {
			fetchCredits();
		}
	}, [isLoggedIn, fetchCredits, updateCredits, credits]);


	const contextValue: CreditsContextType = {
		credits,
		handleCheckout,
	};


	return (
		<CreditsContext.Provider value={contextValue}>
			{children}
		</CreditsContext.Provider>
	);
};


export const useCredits = () => {
	const context = useContext(CreditsContext);
	if (context === null) {
		throw new Error('useCredits must be used within a CreditsProvider');
	}
	return context;
};