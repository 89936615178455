import React, { useRef, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";


interface SignatureMiniatureProps {
  imageUrl: string;
  box: [number, number, number, number];
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    miniatureContainer: {
      maxWidth: 150,
      maxHeight: 100,
      borderRadius: 8,
      overflow: "hidden",
      marginBottom: "5px",
      marginTop: "-15px",
      marginLeft: "5px",
      marginRight: "5px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
      display: 'flex',
      aspectRatio: 150 / 100,
      alignItems: "center",
    },
    canvas: {
      width: '100%',
      height: '100%',
      objectFit: "contain",
    },
  })
);


const SignatureMiniature: React.FC<SignatureMiniatureProps> = ({ imageUrl, box }) => {
  const classes = useStyles();
  const canvasRef = useRef<HTMLCanvasElement>(null);


  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const [x, y, width, height] = box;

    const img = new Image();
    img.onload = () => {
      if (imageUrl.startsWith("data:image/")) {
        // Imagem do backend: desenhar diretamente no canvas
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
      } else {
        // Imagem de nova região: lógica atual

        // Calcula a proporção do canvas
        const canvasAspectRatio = 150 / 100;

        // Calcula a proporção da imagem original
        const imageAspectRatio = width / height;

        // Determina qual dimensão (largura ou altura) deve ser usada para o redimensionamento
        let scaleFactor: number;
        if (imageAspectRatio > canvasAspectRatio) {
          // Imagem mais larga, redimensiona pela largura
          scaleFactor = canvas.width / width;
        } else {
          // Imagem mais alta, redimensiona pela altura
          scaleFactor = canvas.height / height;
        }

        // Calcula as dimensões da imagem redimensionada
        const scaledWidth = width * scaleFactor;
        const scaledHeight = height * scaleFactor;

        // Calcula as coordenadas para centralizar a imagem
        const drawX = (canvas.width - scaledWidth) / 2;
        const drawY = (canvas.height - scaledHeight) / 2;

        canvas.width = scaledWidth;

        ctx.imageSmoothingQuality = "high";

        // Desenha a imagem no canvas
        ctx.drawImage(img, x, y, width, height, drawX, drawY, scaledWidth, scaledHeight);
      }
    };
    img.onerror = (error) => {
      console.error("Erro ao carregar a imagem:", error);
    };
    img.src = imageUrl;
  }, [imageUrl, box]);

  
  return (
    <div className={classes.miniatureContainer}>
      <canvas ref={canvasRef} className={classes.canvas} />
    </div>
  );
};

export default SignatureMiniature;